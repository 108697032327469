_cruxUtils._cruxLocale = {
	"crm.criteria.secondaryfield.valid.check" : "\u0e42\u0e1b\u0e23\u0e14\u0e1b\u0e49\u0e2d\u0e19\u0e04\u0e48\u0e32\u0e17\u0e35\u0e48\u0e15\u0e23\u0e07\u0e01\u0e31\u0e19\u0e2a\u0e33\u0e2b\u0e23\u0e31\u0e1a {0}",
	"crm.label.field":"\u0e40\u0e02\u0e15\u0e02\u0e49\u0e2d\u0e21\u0e39\u0e25",//no i18n
"crm.label.value":"\u0e04\u0e48\u0e32",//no i18n
	"sentiment.criteria.wrongcriteria":"\u0e04\u0e48\u0e32\u0e40\u0e01\u0e13\u0e11\u0e4c\u0e44\u0e21\u0e48\u0e2a\u0e32\u0e21\u0e32\u0e23\u0e16\u0e21\u0e32\u0e01\u0e01\u0e27\u0e48\u0e32 {0}",//no i18n
"crm.mb.field.common.splc":"\u0e44\u0e21\u0e48\u0e2d\u0e19\u0e38\u0e0d\u0e32\u0e15\u0e15\u0e31\u0e27\u0e2d\u0e31\u0e01\u0e02\u0e23\u0e30\u0e1e\u0e34\u0e40\u0e28\u0e29 \u0e1b\u0e49\u0e2d\u0e19\u0e04\u0e48\u0e32\u0e17\u0e35\u0e48\u0e16\u0e39\u0e01\u0e15\u0e49\u0e2d\u0e07",//no i18n
	"crm.label.field.plural":"\u0e40\u0e02\u0e15\u0e02\u0e49\u0e2d\u0e21\u0e39\u0e25",//no i18n
	"crm.label.in.minutes":"{0} (\u0e2b\u0e19\u0e48\u0e27\u0e22\u0e19\u0e32\u0e17\u0e35)",//no i18n
	"crm.security.roles.list":"\u0e23\u0e32\u0e22\u0e01\u0e32\u0e23\u0e1a\u0e17\u0e1a\u0e32\u0e17",//no i18n
"crm.security.roles.lookup.info":"\u0e42\u0e1b\u0e23\u0e14\u0e40\u0e25\u0e37\u0e2d\u0e01\u0e2b\u0e19\u0e36\u0e48\u0e07\u0e1a\u0e17\u0e1a\u0e32\u0e17\u0e08\u0e32\u0e01\u0e23\u0e32\u0e22\u0e01\u0e32\u0e23",//no i18n
"crm.territory.addterritory":"\u0e40\u0e1e\u0e34\u0e48\u0e21\u0e40\u0e02\u0e15\u0e41\u0e14\u0e19",//no i18n
"crm.title.edit.territory":"\u0e41\u0e01\u0e49\u0e44\u0e02\u0e40\u0e02\u0e15\u0e41\u0e14\u0e19",//no i18n
"crm.territory.title.assign.territories":"\u0e21\u0e2d\u0e1a\u0e2b\u0e21\u0e32\u0e22\u0e40\u0e02\u0e15\u0e41\u0e14\u0e19",//no i18n
	"crm.label.context.help":"\u0e27\u0e34\u0e18\u0e35\u0e43\u0e0a\u0e49",//no i18n
"crm.label.from":"\u0e08\u0e32\u0e01",//no i18n
"crm.label.to":"\u0e16\u0e36\u0e07",//no i18n
"workflow.option.webhookFailure.fromDate":"\u0e08\u0e32\u0e01\u0e27\u0e31\u0e19\u0e17\u0e35\u0e48",//no i18n
"workflow.option.webhookFailure.toDate":"\u0e16\u0e36\u0e07\u0e27\u0e31\u0e19\u0e17\u0e35\u0e48",//no i18n
"crm.custom.field.less.than.equalto":"{0} \u0e08\u0e30\u0e15\u0e49\u0e2d\u0e07\u0e19\u0e49\u0e2d\u0e22\u0e01\u0e27\u0e48\u0e32\u0e2b\u0e23\u0e37\u0e2d\u0e40\u0e17\u0e48\u0e32\u0e01\u0e31\u0e1a {1}",//no i18n
"crm.template.listview.search.no.results":"\u0e44\u0e21\u0e48\u0e1e\u0e1a\u0e1c\u0e25\u0e25\u0e31\u0e1e\u0e18\u0e4c",//no i18n
"crm.wf.usage.date.criteria.error.msg":"\u0e27\u0e31\u0e19\u0e17\u0e35\u0e48 \u201d\u0e08\u0e32\u0e01\u201c \u0e44\u0e21\u0e48\u0e2a\u0e32\u0e21\u0e32\u0e23\u0e16\u0e40\u0e1b\u0e47\u0e19\u0e27\u0e31\u0e19\u0e17\u0e35\u0e48\u0e2b\u0e25\u0e31\u0e07\u0e08\u0e32\u0e01\u0e27\u0e31\u0e19\u0e17\u0e35\u0e48 \u201d\u0e16\u0e36\u0e07\u201c",//no i18n
"crm.label.tag.new":"\u0e41\u0e17\u0e47\u0e01\u0e43\u0e2b\u0e21\u0e48",//no i18n
"crm.label.enter.tag":"\u0e1b\u0e49\u0e2d\u0e19\u0e41\u0e17\u0e47\u0e01",//no i18n
"Administrator":"\u0e1c\u0e39\u0e49\u0e14\u0e39\u0e41\u0e25\u0e23\u0e30\u0e1a\u0e1a",//no i18n
"Standard":"\u0e21\u0e32\u0e15\u0e23\u0e10\u0e32\u0e19",//no i18n
"crux.comboBox.max.limit":"\u0e04\u0e38\u0e13\u0e44\u0e21\u0e48\u0e2a\u0e32\u0e21\u0e32\u0e23\u0e16\u0e40\u0e25\u0e37\u0e2d\u0e01\u0e44\u0e14\u0e49\u0e21\u0e32\u0e01\u0e01\u0e27\u0e48\u0e32 {0} {1} \u0e23\u0e32\u0e22\u0e01\u0e32\u0e23.",//no i18n
"crm.button.add":"\u0e40\u0e1e\u0e34\u0e48\u0e21",//no i18n
"crm.label.users":"\u0e1c\u0e39\u0e49\u0e43\u0e0a\u0e49",//no i18n
"crm.workflow.alert.roles":"\u0e1a\u0e17\u0e1a\u0e32\u0e17",//no i18n
"crm.security.groups":"\u0e01\u0e25\u0e38\u0e48\u0e21",//no i18n
"crm.label.available":"\u0e1e\u0e23\u0e49\u0e2d\u0e21\u0e43\u0e0a\u0e49\u0e07\u0e32\u0e19",//no i18n
"crm.label.assign.manually":"\u0e21\u0e2d\u0e1a\u0e2b\u0e21\u0e32\u0e22",//no i18n
"crm.globalsearch.option.all":"\u0e17\u0e31\u0e49\u0e07\u0e2b\u0e21\u0e14",//no i18n
"webform.status.Active":"\u0e40\u0e1b\u0e34\u0e14\u0e17\u0e33\u0e07\u0e32\u0e19\u0e2d\u0e22\u0e39\u0e48",//no i18n
"Inactive":"\u0e44\u0e21\u0e48\u0e44\u0e14\u0e49\u0e40\u0e1b\u0e34\u0e14\u0e43\u0e0a\u0e49\u0e07\u0e32\u0e19",//no i18n
"Confirmed":"\u0e22\u0e37\u0e19\u0e22\u0e31\u0e19\u0e41\u0e25\u0e49\u0e27",//no i18n
"DeletedUser":"\u0e25\u0e1a\u0e41\u0e25\u0e49\u0e27",//no i18n
"crm.user.component.unconfirmed":"\u0e22\u0e31\u0e07\u0e44\u0e21\u0e48\u0e22\u0e37\u0e19\u0e22\u0e31\u0e19",//no i18n
"crm.feed.group.admin":"\u0e1c\u0e39\u0e49\u0e14\u0e39\u0e41\u0e25\u0e23\u0e30\u0e1a\u0e1a",//no i18n
"crm.ln.lable.current":"\u0e1b\u0e31\u0e08\u0e08\u0e38\u0e1a\u0e31\u0e19",//no i18n
"crm.security.group.users.empty":"\u0e44\u0e21\u0e48\u0e1e\u0e1a\u0e1c\u0e39\u0e49\u0e43\u0e0a\u0e49",//no i18n
"crm.label.picklist.none":"\u0e44\u0e21\u0e48\u0e21\u0e35",//no i18n
"crm.label.selected":"\u0e40\u0e25\u0e37\u0e2d\u0e01\u0e41\u0e25\u0e49\u0e27",//no i18n
"crm.label.notSelected":"\u0e44\u0e21\u0e48\u0e44\u0e14\u0e49\u0e40\u0e25\u0e37\u0e2d\u0e01",//no i18n
"AM":"AM",//no i18n
"Call":"\u0e01\u0e32\u0e23\u0e42\u0e17\u0e23",//no i18n
"crm.phoneNo.Link.Title":"\u0e42\u0e17\u0e23\u0e1c\u0e48\u0e32\u0e19 Skype",//no i18n
"crm.button.cancel":"\u0e22\u0e01\u0e40\u0e25\u0e34\u0e01",//no i18n
"crm.button.save":"\u0e1a\u0e31\u0e19\u0e17\u0e36\u0e01",//no i18n
"crm.no.data.found":"\u0e44\u0e21\u0e48\u0e1e\u0e1a\u0e02\u0e49\u0e2d\u0e21\u0e39\u0e25",//no i18n
"crm.usrpop.non.selected":"\u0e1c\u0e39\u0e49\u0e43\u0e0a\u0e49\u0e17\u0e35\u0e48\u0e40\u0e25\u0e37\u0e2d\u0e01",//no i18n
"crm.zti.label.user":"\u0e0a\u0e37\u0e48\u0e2d\u0e1c\u0e39\u0e49\u0e43\u0e0a\u0e49",//no i18n
"crm.auditlog.user":"\u0e1c\u0e39\u0e49\u0e43\u0e0a\u0e49",//no i18n
"cob.role":"\u0e1a\u0e17\u0e1a\u0e32\u0e17",//no i18n
"zoho.email":"\u0e2d\u0e35\u0e40\u0e21\u0e25",//no i18n
"Profile":"\u0e42\u0e1b\u0e23\u0e44\u0e1f\u0e25\u0e4c",//no i18n
"crm.label.no.options.found":"\u0e44\u0e21\u0e48\u0e1e\u0e1a\u0e15\u0e31\u0e27\u0e40\u0e25\u0e37\u0e2d\u0e01",//no i18n
"crm.globalsearch.search.title":"\u0e04\u0e49\u0e19\u0e2b\u0e32",//no i18n
"None":"\u0e44\u0e21\u0e48\u0e21\u0e35",//no i18n
"crm.label.criteria.pattern":"\u0e23\u0e39\u0e1b\u0e41\u0e1a\u0e1a\u0e40\u0e01\u0e13\u0e11\u0e4c",//no i18n
"crm.label.edit.criteria.pattern":"\u0e41\u0e01\u0e49\u0e44\u0e02\u0e23\u0e39\u0e1b\u0e41\u0e1a\u0e1a",//no i18n
"criteria.error.alert.brackets.count.mismatch":"\u0e27\u0e07\u0e40\u0e25\u0e47\u0e1a\u0e40\u0e2b\u0e25\u0e35\u0e48\u0e22\u0e21\u0e02\u0e2d\u0e07\u0e23\u0e39\u0e1b\u0e41\u0e1a\u0e1a\u0e44\u0e21\u0e48\u0e15\u0e23\u0e07\u0e01\u0e31\u0e19",//no i18n
"criteria.error.alert.brackets.invalid":"\u0e21\u0e35\u0e27\u0e07\u0e40\u0e25\u0e47\u0e1a\u0e40\u0e2b\u0e25\u0e35\u0e48\u0e22\u0e21\u0e44\u0e21\u0e48\u0e16\u0e39\u0e01\u0e15\u0e49\u0e2d\u0e07\u0e25\u0e49\u0e2d\u0e21\u0e23\u0e2d\u0e1a\u0e15\u0e31\u0e27\u0e14\u0e33\u0e40\u0e19\u0e34\u0e19\u0e01\u0e32\u0e23\u0e40\u0e07\u0e37\u0e48\u0e2d\u0e19\u0e44\u0e02",//no i18n
"crm.criteria.number.notmatch.check":"\u0e42\u0e1b\u0e23\u0e14\u0e15\u0e23\u0e27\u0e08\u0e2a\u0e2d\u0e1a\u0e23\u0e39\u0e1b\u0e41\u0e1a\u0e1a\u0e17\u0e35\u0e48 {0}",//no i18n
"criteria.error.alert.other.params":"\u0e40\u0e19\u0e37\u0e49\u0e2d\u0e2b\u0e32\u0e43\u0e19\u0e23\u0e39\u0e1b\u0e41\u0e1a\u0e1a\u0e19\u0e35\u0e49\u0e44\u0e21\u0e48\u0e16\u0e39\u0e01\u0e15\u0e49\u0e2d\u0e07",//no i18n
"crm.label.search.for.users":"\u0e04\u0e49\u0e19\u0e2b\u0e32\u0e1c\u0e39\u0e49\u0e43\u0e0a\u0e49",//no i18n
"criteria.error.alert.andor.rowcount.mismatch":"\u0e23\u0e39\u0e1b\u0e41\u0e1a\u0e1a\u0e40\u0e01\u0e13\u0e11\u0e4c\u0e44\u0e21\u0e48\u0e15\u0e23\u0e07\u0e01\u0e31\u0e1a\u0e40\u0e07\u0e37\u0e48\u0e2d\u0e19\u0e44\u0e02\u0e17\u0e35\u0e48\u0e04\u0e38\u0e13\u0e40\u0e25\u0e37\u0e2d\u0e01",//no i18n
"criteria.error.alert.critnum.rowcount.mismatch":"\u0e23\u0e39\u0e1b\u0e41\u0e1a\u0e1a\u0e40\u0e01\u0e13\u0e11\u0e4c\u0e44\u0e21\u0e48\u0e15\u0e23\u0e07\u0e01\u0e31\u0e1a\u0e40\u0e07\u0e37\u0e48\u0e2d\u0e19\u0e44\u0e02\u0e17\u0e35\u0e48\u0e04\u0e38\u0e13\u0e40\u0e25\u0e37\u0e2d\u0e01",//no i18n
"and":"\u0e41\u0e25\u0e30",//no i18n
"or":"\u0e2b\u0e23\u0e37\u0e2d",//no i18n
"crm.label.or":"\u0e2b\u0e23\u0e37\u0e2d",//no i18n
"crm.label.and":"\u0e41\u0e25\u0e30",//no i18n
"crm.criteria.fieldlabel.valid.check":"\u0e42\u0e1b\u0e23\u0e14\u0e1b\u0e49\u0e2d\u0e19\u0e1b\u0e49\u0e32\u0e22\u0e0a\u0e37\u0e48\u0e2d\u0e40\u0e02\u0e15\u0e02\u0e49\u0e2d\u0e21\u0e39\u0e25\u0e17\u0e35\u0e48\u0e16\u0e39\u0e01\u0e15\u0e49\u0e2d\u0e07\u0e43\u0e19\u0e41\u0e16\u0e27 {0}",//no i18n
"crm.criteria.condition.valid.check":"\u0e42\u0e1b\u0e23\u0e14\u0e1b\u0e49\u0e2d\u0e19\u0e40\u0e07\u0e37\u0e48\u0e2d\u0e19\u0e44\u0e02\u0e17\u0e35\u0e48\u0e16\u0e39\u0e01\u0e15\u0e49\u0e2d\u0e07\u0e2a\u0e33\u0e2b\u0e23\u0e31\u0e1a {0}",//no i18n
"crm.field.valid.check":"\u0e42\u0e1b\u0e23\u0e14\u0e1b\u0e49\u0e2d\u0e19 {0} \u0e17\u0e35\u0e48\u0e16\u0e39\u0e01\u0e15\u0e49\u0e2d\u0e07",//no i18n
"crm.custom.field.less.than.to":"\u0e0a\u0e48\u0e27\u0e07 <i>\u0e08\u0e32\u0e01</i> \u0e15\u0e49\u0e2d\u0e07\u0e44\u0e21\u0e48\u0e21\u0e32\u0e01\u0e01\u0e27\u0e48\u0e32\u0e0a\u0e48\u0e27\u0e07 <i>\u0e16\u0e36\u0e07</i>",//no i18n
"crm.alert.label.savepattern":"\u0e1a\u0e31\u0e19\u0e17\u0e36\u0e01\u0e23\u0e39\u0e1b\u0e41\u0e1a\u0e1a\u0e01\u0e48\u0e2d\u0e19\u0e40\u0e1b\u0e25\u0e35\u0e48\u0e22\u0e19\u0e41\u0e1b\u0e25\u0e07\u0e40\u0e01\u0e13\u0e11\u0e4c",//no i18n
"crm.criteria.max.rowcnt.exceeds":"\u0e04\u0e38\u0e13\u0e44\u0e21\u0e48\u0e2a\u0e32\u0e21\u0e32\u0e23\u0e16\u0e40\u0e1e\u0e34\u0e48\u0e21\u0e40\u0e01\u0e13\u0e11\u0e4c\u0e40\u0e1e\u0e34\u0e48\u0e21\u0e40\u0e15\u0e34\u0e21",//no i18n
"is":"\u0e40\u0e1b\u0e47\u0e19",//no i18n
"isn\'t":"\u0e44\u0e21\u0e48\u0e43\u0e0a\u0e48",//no i18n
"contains":"\u0e21\u0e35",//no i18n
"doesn\'t\ contain":"\u0e44\u0e21\u0e48\u0e21\u0e35",//no i18n
"starts with":"\u0e40\u0e23\u0e34\u0e48\u0e21\u0e14\u0e49\u0e27\u0e22",//no i18n
"ends with":"\u0e2a\u0e34\u0e49\u0e19\u0e2a\u0e38\u0e14\u0e14\u0e49\u0e27\u0e22",//no i18n
"is empty":"\u0e27\u0e48\u0e32\u0e07\u0e40\u0e1b\u0e25\u0e48\u0e32",//no i18n
"is not empty":"\u0e44\u0e21\u0e48\u0e27\u0e48\u0e32\u0e07\u0e40\u0e1b\u0e25\u0e48\u0e32",//no i18n
"is before":"\u0e01\u0e48\u0e2d\u0e19",//no i18n
"is after":"\u0e2b\u0e25\u0e31\u0e07",//no i18n
"between":"\u0e23\u0e30\u0e2b\u0e27\u0e48\u0e32\u0e07",//no i18n
"not between":"\u0e44\u0e21\u0e48\u0e43\u0e0a\u0e48\u0e23\u0e30\u0e2b\u0e27\u0e48\u0e32\u0e07",//no i18n
"Today":"\u0e27\u0e31\u0e19\u0e19\u0e35\u0e49",//no i18n
"Tommorow":"\u0e27\u0e31\u0e19\u0e1e\u0e23\u0e38\u0e48\u0e07\u0e19\u0e35\u0e49",//no i18n
"Tommorow Onwards":"\u0e01\u0e32\u0e23\u0e40\u0e23\u0e34\u0e48\u0e21\u0e27\u0e31\u0e19\u0e1e\u0e23\u0e38\u0e48\u0e07\u0e19\u0e35\u0e49",//no i18n
"Yesterday":"\u0e40\u0e21\u0e37\u0e48\u0e2d\u0e27\u0e32\u0e19",//no i18n
"Till Yesterday":"\u0e08\u0e19\u0e16\u0e36\u0e07\u0e40\u0e21\u0e37\u0e48\u0e2d\u0e27\u0e32\u0e19",//no i18n
"Last Month":"\u0e40\u0e14\u0e37\u0e2d\u0e19\u0e17\u0e35\u0e48\u0e41\u0e25\u0e49\u0e27",//no i18n
"Current Month":"\u0e40\u0e14\u0e37\u0e2d\u0e19\u0e1b\u0e31\u0e08\u0e08\u0e38\u0e1a\u0e31\u0e19",//no i18n
"Next Month":"\u0e40\u0e14\u0e37\u0e2d\u0e19\u0e16\u0e31\u0e14\u0e44\u0e1b",//no i18n
"Last Week":"\u0e2a\u0e31\u0e1b\u0e14\u0e32\u0e2b\u0e4c\u0e17\u0e35\u0e48\u0e41\u0e25\u0e49\u0e27",//no i18n
"Current Week":"\u0e2a\u0e31\u0e1b\u0e14\u0e32\u0e2b\u0e4c\u0e1b\u0e31\u0e08\u0e08\u0e38\u0e1a\u0e31\u0e19",//no i18n
"Next Week":"\u0e2a\u0e31\u0e1b\u0e14\u0e32\u0e2b\u0e4c\u0e16\u0e31\u0e14\u0e44\u0e1b",//no i18n
"Age in Days":"\u0e2d\u0e32\u0e22\u0e38\u0e43\u0e19\u0e41\u0e1a\u0e1a\u0e27\u0e31\u0e19",//no i18n
"Due in Days":"\u0e16\u0e36\u0e07\u0e01\u0e33\u0e2b\u0e19\u0e14\u0e43\u0e19\u0e41\u0e1a\u0e1a\u0e27\u0e31\u0e19",//no i18n

"Scheduled":"\u0e01\u0e33\u0e2b\u0e19\u0e14\u0e01\u0e32\u0e23\u0e41\u0e25\u0e49\u0e27",//no i18n
"Attended Dialled":"\u0e01\u0e32\u0e23\u0e42\u0e17\u0e23\u0e2d\u0e2d\u0e01\u0e17\u0e35\u0e48\u0e21\u0e35\u0e04\u0e19\u0e23\u0e31\u0e1a\u0e2a\u0e32\u0e22",//no i18n
"Unattended Dialled":"\u0e01\u0e32\u0e23\u0e42\u0e17\u0e23\u0e2d\u0e2d\u0e01\u0e17\u0e35\u0e48\u0e44\u0e21\u0e48\u0e21\u0e35\u0e04\u0e19\u0e23\u0e31\u0e1a\u0e2a\u0e32\u0e22",//no i18n
"Overdue":"\u0e40\u0e25\u0e22\u0e01\u0e33\u0e2b\u0e19\u0e14",//no i18n
"Cancelled":"\u0e22\u0e01\u0e40\u0e25\u0e34\u0e01\u0e41\u0e25\u0e49\u0e27",//no i18n
"Received":"\u0e44\u0e14\u0e49\u0e23\u0e31\u0e1a\u0e41\u0e25\u0e49\u0e27",//no i18n
"Missed":"\u0e1e\u0e25\u0e32\u0e14\u0e23\u0e31\u0e1a\u0e2a\u0e32\u0e22",//no i18n
"crm.alert.character.not.allowed":"{0} \u0e44\u0e21\u0e48\u0e44\u0e14\u0e49\u0e23\u0e31\u0e1a\u0e2d\u0e19\u0e38\u0e0d\u0e32\u0e15",//no i18n
"crm.condition.in.last":"\u0e43\u0e19\u0e04\u0e23\u0e31\u0e49\u0e07\u0e25\u0e48\u0e32\u0e2a\u0e38\u0e14",//no i18n
"crm.zinvoice.dueIn":"\u0e04\u0e23\u0e1a\u0e01\u0e33\u0e2b\u0e19\u0e14\u0e43\u0e19",//no i18n
"on":"\u0e40\u0e21\u0e37\u0e48\u0e2d",//no i18n
"before":"\u0e01\u0e48\u0e2d\u0e19",//no i18n
"crm.label.general.small.after":"\u0e2b\u0e25\u0e31\u0e07",//no i18n
"crm.thisweek":"\u0e2a\u0e31\u0e1b\u0e14\u0e32\u0e2b\u0e4c\u0e19\u0e35\u0e49",//no i18n
"crm.label.this.month":"\u0e40\u0e14\u0e37\u0e2d\u0e19\u0e19\u0e35\u0e49",//no i18n
"crm.thisyear":"\u0e1b\u0e35\u0e19\u0e35\u0e49",//no i18n
"crm.source.user.and.system":"\u0e1c\u0e39\u0e49\u0e43\u0e0a\u0e49\u0e41\u0e25\u0e30\u0e23\u0e30\u0e1a\u0e1a",//no i18n
"crm.source.user.or.system":"\u0e1c\u0e39\u0e49\u0e43\u0e0a\u0e49\u0e2b\u0e23\u0e37\u0e2d\u0e23\u0e30\u0e1a\u0e1a",//no i18n
"crm.label.system2":"\u0e23\u0e30\u0e1a\u0e1a",//no i18n
"crm.source.user.only":"\u0e40\u0e09\u0e1e\u0e32\u0e30\u0e1c\u0e39\u0e49\u0e43\u0e0a\u0e49\u0e40\u0e17\u0e48\u0e32\u0e19\u0e31\u0e49\u0e19",//no i18n
"crm.source.system.only":"\u0e42\u0e14\u0e22\u0e23\u0e30\u0e1a\u0e1a\u0e40\u0e17\u0e48\u0e32\u0e19\u0e31\u0e49\u0e19",//no i18n
"crm.condition.till.today":"\u0e08\u0e19\u0e16\u0e36\u0e07\u0e27\u0e31\u0e19\u0e19\u0e35\u0e49",//no i18n
"game.month.left":"1 \u0e40\u0e14\u0e37\u0e2d\u0e19",//no i18n
"game.months.left":"{0} \u0e40\u0e14\u0e37\u0e2d\u0e19",//no i18n
"crm.condition.last.30.days":"\u0e43\u0e19\u0e0a\u0e48\u0e27\u0e07 30 \u0e27\u0e31\u0e19\u0e17\u0e35\u0e48\u0e41\u0e25\u0e49\u0e27",//no i18n
"crm.condition.last.60.days":"\u0e43\u0e19\u0e0a\u0e48\u0e27\u0e07 60 \u0e27\u0e31\u0e19\u0e17\u0e35\u0e48\u0e41\u0e25\u0e49\u0e27",//no i18n
"crm.condition.last.90.days":"\u0e43\u0e19\u0e0a\u0e48\u0e27\u0e07 90 \u0e27\u0e31\u0e19\u0e17\u0e35\u0e48\u0e41\u0e25\u0e49\u0e27",//no i18n
"crm.label.filter.typehere":"\u0e1e\u0e34\u0e21\u0e1e\u0e4c\u0e17\u0e35\u0e48\u0e19\u0e35\u0e48",//no i18n
"crm.filter.is.not":"\u0e44\u0e21\u0e48\u0e43\u0e0a\u0e48",//no i18n
"crm.condition.until.now":"\u0e08\u0e19\u0e16\u0e36\u0e07\u0e15\u0e2d\u0e19\u0e19\u0e35\u0e49",//no i18n
"crm.filter.email.isblocked":"\u0e16\u0e39\u0e01\u0e1a\u0e25\u0e47\u0e2d\u0e01",//no i18n
"crm.filter.email.isnotblocked":"\u0e44\u0e21\u0e48\u0e16\u0e39\u0e01\u0e1a\u0e25\u0e47\u0e2d\u0e01",//no i18n
"crm.label.no.results.match":"\u0e44\u0e21\u0e48\u0e1e\u0e1a\u0e1c\u0e25\u0e17\u0e35\u0e48\u0e15\u0e23\u0e07\u0e01\u0e31\u0e19",//no i18n
"crm.label.select.user":"\u0e04\u0e25\u0e34\u0e01\u0e40\u0e1e\u0e37\u0e48\u0e2d\u0e40\u0e25\u0e37\u0e2d\u0e01\u0e1c\u0e39\u0e49\u0e43\u0e0a\u0e49",//no i18n
"current.logged.in.user":"\u0e1c\u0e39\u0e49\u0e43\u0e0a\u0e49\u0e17\u0e35\u0e48\u0e40\u0e02\u0e49\u0e32\u0e2a\u0e39\u0e48\u0e23\u0e30\u0e1a\u0e1a",//no i18n
"current.logged.in.user.definition":"\u0e1c\u0e39\u0e49\u0e43\u0e0a\u0e49\u0e17\u0e35\u0e48\u0e2a\u0e23\u0e49\u0e32\u0e07\u0e01\u0e32\u0e23\u0e14\u0e33\u0e40\u0e19\u0e34\u0e19\u0e01\u0e32\u0e23\u0e02\u0e2d\u0e07\u0e23\u0e30\u0e40\u0e1a\u0e35\u0e22\u0e19",//no i18n
"crm.security.group":"\u0e01\u0e25\u0e38\u0e48\u0e21",//no i18n
"crm.security.role":"\u0e1a\u0e17\u0e1a\u0e32\u0e17",//no i18n
"Date":"\u0e27\u0e31\u0e19\u0e17\u0e35\u0e48",//no i18n
"crm.field.valid.decimal.check2":"\u0e15\u0e33\u0e41\u0e2b\u0e19\u0e48\u0e07\u0e17\u0e28\u0e19\u0e34\u0e22\u0e21\u0e2a\u0e33\u0e2b\u0e23\u0e31\u0e1a\u0e40\u0e02\u0e15\u0e02\u0e49\u0e2d\u0e21\u0e39\u0e25 <i>{0}</i> \u0e08\u0e30\u0e15\u0e49\u0e2d\u0e07\u0e19\u0e49\u0e2d\u0e22\u0e01\u0e27\u0e48\u0e32\u0e2b\u0e23\u0e37\u0e2d\u0e40\u0e17\u0e48\u0e32\u0e01\u0e31\u0e1a {1}",//no i18n
"crm.field.empty.check":"{0} \u0e44\u0e21\u0e48\u0e2a\u0e32\u0e21\u0e32\u0e23\u0e16\u0e27\u0e48\u0e32\u0e07\u0e40\u0e1b\u0e25\u0e48\u0e32",//no i18n

//filter related keys-start
"crm.lead.prediction.convert.high":"\u0e2a\u0e39\u0e07",//no i18n
"crm.inv.mail.time.two.days.subject":"{0} - {1}",//no i18n
"crm.lead.prediction.convert.medium":"\u0e1b\u0e32\u0e19\u0e01\u0e25\u0e32\u0e07",//no i18n
"crm.lead.prediction.convert.low":"\u0e15\u0e48\u0e33",//no i18n
"crm.lead.prediction.tooltip.convert":"\u0e04\u0e27\u0e32\u0e21\u0e40\u0e1b\u0e47\u0e19\u0e44\u0e1b\u0e44\u0e14\u0e49",//no i18n
"crm.lead.prediction.tooltip.score":"\u0e04\u0e30\u0e41\u0e19\u0e19",//no i18n
"Planned":"\u0e27\u0e32\u0e07\u0e41\u0e1c\u0e19\u0e41\u0e25\u0e49\u0e27",//no i18n
"Invited":"\u0e44\u0e14\u0e49\u0e40\u0e0a\u0e34\u0e0d",//no i18n
"Sent":"\u0e2a\u0e48\u0e07\u0e41\u0e25\u0e49\u0e27",//no i18n
"Received":"\u0e44\u0e14\u0e49\u0e23\u0e31\u0e1a\u0e41\u0e25\u0e49\u0e27",//no i18n
"Opened":"\u0e40\u0e1b\u0e34\u0e14\u0e41\u0e25\u0e49\u0e27",//no i18n
"Responded":"\u0e15\u0e2d\u0e1a\u0e2a\u0e19\u0e2d\u0e07\u0e41\u0e25\u0e49\u0e27",//no i18n
"Bounced":"\u0e15\u0e35\u0e01\u0e25\u0e31\u0e1a\u0e41\u0e25\u0e49\u0e27",//no i18n
"Opted\ Out":"\u0e40\u0e25\u0e37\u0e2d\u0e01\u0e44\u0e21\u0e48\u0e23\u0e31\u0e1a\u0e41\u0e25\u0e49\u0e27",//no i18n
"crm.filter.label.with.open":"\u0e21\u0e35 {0} \u0e17\u0e35\u0e48\u0e40\u0e1b\u0e34\u0e14\u0e2d\u0e22\u0e39\u0e48",//no i18n
"crm.filter.label.without.open":"\u0e17\u0e35\u0e48\u0e44\u0e21\u0e48\u0e21\u0e35 {0} \u0e17\u0e35\u0e48\u0e40\u0e1b\u0e34\u0e14\u0e2d\u0e22\u0e39\u0e48",//no i18n
"crm.filter.label.without.any":"\u0e42\u0e14\u0e22\u0e44\u0e21\u0e48\u0e21\u0e35 {0}",//no i18n
"crm.filter.label.with.module":"\u0e21\u0e35 {0}",//no i18n
"Overdue":"\u0e40\u0e25\u0e22\u0e01\u0e33\u0e2b\u0e19\u0e14",//no i18n
"crm.filter.label.activity.due":"{0} \u0e04\u0e23\u0e1a\u0e01\u0e33\u0e2b\u0e19\u0e14",//no i18n
"crm.filter.label.activity.done":"{0} \u0e17\u0e33\u0e41\u0e25\u0e49\u0e27",//no i18n
"Notes":"\u0e1a\u0e31\u0e19\u0e17\u0e36\u0e01\u0e22\u0e48\u0e2d",//no i18n
"crm.filter.label.notes.added":"\u0e40\u0e1e\u0e34\u0e48\u0e21\u0e1a\u0e31\u0e19\u0e17\u0e36\u0e01\u0e22\u0e48\u0e2d\u0e41\u0e25\u0e49\u0e27",//no i18n
"crm.label.filter.email.status":"\u0e2a\u0e16\u0e32\u0e19\u0e30\u0e2d\u0e35\u0e40\u0e21\u0e25\u0e25\u0e48\u0e32\u0e2a\u0e38\u0e14",//no i18n
"crm.label.filter.email.clicked":"\u0e04\u0e25\u0e34\u0e01\u0e41\u0e25\u0e49\u0e27",//no i18n
"crm.label.filter.email.responded":"\u0e17\u0e35\u0e48\u0e15\u0e2d\u0e1a\u0e2a\u0e19\u0e2d\u0e07", //no i18n
"crm.label.filter.email.info":"\u0e01\u0e23\u0e2d\u0e07\u0e23\u0e30\u0e40\u0e1a\u0e35\u0e22\u0e19\u0e2d\u0e34\u0e07\u0e15\u0e32\u0e21\u0e2a\u0e16\u0e32\u0e19\u0e30\u0e25\u0e48\u0e32\u0e2a\u0e38\u0e14\u0e02\u0e2d\u0e07\u0e2d\u0e35\u0e40\u0e21\u0e25\u0e17\u0e35\u0e48\u0e2a\u0e48\u0e07/\u0e23\u0e31\u0e1a\u0e02\u0e2d\u0e07\u0e04\u0e38\u0e13",//no i18n
"crm.filter.label.sent":"\u0e2a\u0e48\u0e07\u0e41\u0e25\u0e49\u0e27",//no i18n
"crm.filter.label.not.sent":"\u0e44\u0e21\u0e48\u0e44\u0e14\u0e49\u0e2a\u0e48\u0e07",//no i18n
"crm.filter.label.opened":"\u0e40\u0e1b\u0e34\u0e14\u0e41\u0e25\u0e49\u0e27",//no i18n
"crm.filter.label.not.opened":"\u0e44\u0e21\u0e48\u0e44\u0e14\u0e40\u0e1b\u0e34\u0e14",//no i18n
"crm.filter.label.received":"\u0e44\u0e14\u0e49\u0e23\u0e31\u0e1a\u0e41\u0e25\u0e49\u0e27",//no i18n
"crm.filter.label.not.received":"\u0e44\u0e21\u0e48\u0e44\u0e14\u0e49\u0e23\u0e31\u0e1a",//no i18n
"crm.filter.label.bounced":"\u0e15\u0e35\u0e01\u0e25\u0e31\u0e1a\u0e41\u0e25\u0e49\u0e27",//no i18n
"crm.filter.label.opened.not.replied":"\u0e17\u0e35\u0e48\u0e40\u0e1b\u0e34\u0e14\u0e41\u0e25\u0e49\u0e27\u0e41\u0e25\u0e30\u0e44\u0e21\u0e48\u0e44\u0e14\u0e49\u0e15\u0e2d\u0e1a\u0e01\u0e25\u0e31\u0e1a", //no i18n
"crm.filter.label.any":"\u0e23\u0e32\u0e22\u0e01\u0e32\u0e23\u0e2b\u0e19\u0e36\u0e48\u0e07\u0e43\u0e14\u0e02\u0e49\u0e32\u0e07\u0e15\u0e49\u0e19",//no i18n
"crm.zia.config.potential.amount":"\u0e08\u0e33\u0e19\u0e27\u0e19\u0e40\u0e07\u0e34\u0e19 {0}",//no i18n
"Quote\ Stage":"\u0e02\u0e31\u0e49\u0e19\u0e15\u0e2d\u0e19\u0e17\u0e35\u0e48 {0}",//no i18n
"crm.module.owner":"\u0e40\u0e08\u0e49\u0e32\u0e02\u0e2d\u0e07 {0}",//no i18n
"Potential\ Closing\ Date":"\u0e27\u0e31\u0e19\u0e17\u0e35\u0e48\u0e1b\u0e34\u0e14 {0}",//no i18n
"crm.lead.prediction.likely.convert":"\u0e21\u0e35\u0e04\u0e27\u0e32\u0e21\u0e40\u0e1b\u0e47\u0e19\u0e44\u0e1b\u0e44\u0e14\u0e49\u0e17\u0e35\u0e48\u0e08\u0e30\u0e41\u0e1b\u0e25\u0e07",//no i18n
"crm.predictions.feature.label":"\u0e01\u0e32\u0e23\u0e04\u0e32\u0e14\u0e40\u0e14\u0e32",//no i18n
"crm.intelligence.prediction.likelywin":"\u0e21\u0e35\u0e04\u0e27\u0e32\u0e21\u0e40\u0e1b\u0e47\u0e19\u0e44\u0e1b\u0e44\u0e14\u0e49\u0e17\u0e35\u0e48\u0e08\u0e30\u0e44\u0e14\u0e49\u0e23\u0e31\u0e1a\u0e18\u0e38\u0e23\u0e01\u0e34\u0e08",//no i18n
"crm.intelligence.prediction.likelylose":"\u0e21\u0e35\u0e04\u0e27\u0e32\u0e21\u0e40\u0e1b\u0e47\u0e19\u0e44\u0e1b\u0e44\u0e14\u0e49\u0e17\u0e35\u0e48\u0e08\u0e30\u0e2a\u0e39\u0e0d\u0e40\u0e2a\u0e35\u0e22",//no i18n
"crm.intelligence.prediction.halfchance":"\u0e42\u0e2d\u0e01\u0e32\u0e2a 50:50",//no i18n
"crm.intelligence.prediction.score":"\u0e04\u0e30\u0e41\u0e19\u0e19\u0e01\u0e32\u0e23\u0e04\u0e32\u0e14\u0e40\u0e14\u0e32",//no i18n
"crm.lead.prediction.recent.score":"\u0e04\u0e30\u0e41\u0e19\u0e19\u0e01\u0e32\u0e23\u0e04\u0e32\u0e14\u0e40\u0e14\u0e32\u0e25\u0e48\u0e32\u0e2a\u0e38\u0e14",//no i18n
"crm.intelligence.prediction.lastconv":"\u0e01\u0e32\u0e23\u0e41\u0e1b\u0e25\u0e07 3 \u0e23\u0e32\u0e22\u0e01\u0e32\u0e23\u0e25\u0e48\u0e32\u0e2a\u0e38\u0e14",//no i18n
"crm.intelligence.prediction.recordsfocus":"\u0e23\u0e30\u0e40\u0e1a\u0e35\u0e22\u0e19\u0e17\u0e35\u0e48\u0e08\u0e30\u0e21\u0e38\u0e48\u0e07\u0e40\u0e19\u0e49\u0e19",//no i18n
"crm.intelligence.prediction.slowmoving":"\u0e01\u0e32\u0e23\u0e40\u0e04\u0e25\u0e37\u0e48\u0e2d\u0e19\u0e44\u0e2b\u0e27\u0e0a\u0e49\u0e32",//no i18n
"crm.intelligence.prediction.trend.down":"\u0e41\u0e19\u0e27\u0e42\u0e19\u0e49\u0e21\u0e25\u0e14\u0e25\u0e07\u0e25\u0e48\u0e32\u0e2a\u0e38\u0e14",//no i18n
"crm.label.touched.records":"\u0e23\u0e30\u0e40\u0e1a\u0e35\u0e22\u0e19\u0e17\u0e35\u0e48\u0e15\u0e34\u0e14\u0e15\u0e48\u0e2d\u0e41\u0e25\u0e49\u0e27",//no i18n
"crm.label.untouched.records":"\u0e23\u0e30\u0e40\u0e1a\u0e35\u0e22\u0e19\u0e17\u0e35\u0e48\u0e22\u0e31\u0e07\u0e44\u0e21\u0e48\u0e15\u0e34\u0e14\u0e15\u0e48\u0e2d",//no i18n
"crm.label.record.action":"\u0e01\u0e32\u0e23\u0e14\u0e33\u0e40\u0e19\u0e34\u0e19\u0e01\u0e32\u0e23\u0e01\u0e31\u0e1a\u0e23\u0e30\u0e40\u0e1a\u0e35\u0e22\u0e19",//no i18n
"workflow.rule.view.label.Modified":"\u0e41\u0e01\u0e49\u0e44\u0e02\u0e41\u0e25\u0e49\u0e27",//no i18n
"crm.label.not.modified":"\u0e22\u0e31\u0e07\u0e44\u0e21\u0e48\u0e44\u0e14\u0e49\u0e41\u0e01\u0e49\u0e44\u0e02",//no i18n
"crm.label.related.records.action":"\u0e01\u0e32\u0e23\u0e14\u0e33\u0e40\u0e19\u0e34\u0e19\u0e01\u0e32\u0e23\u0e01\u0e31\u0e1a\u0e23\u0e30\u0e40\u0e1a\u0e35\u0e22\u0e19\u0e17\u0e35\u0e48\u0e40\u0e01\u0e35\u0e48\u0e22\u0e27\u0e02\u0e49\u0e2d\u0e07",//no i18n
"Done":"\u0e40\u0e2a\u0e23\u0e47\u0e08\u0e2a\u0e34\u0e49\u0e19",//no i18n
"crm.label.not.done":"\u0e22\u0e31\u0e07\u0e44\u0e21\u0e48\u0e17\u0e33",//no i18n
"sentiment.model":"\u0e04\u0e27\u0e32\u0e21\u0e04\u0e34\u0e14\u0e40\u0e2b\u0e47\u0e19\u0e02\u0e2d\u0e07\u0e2d\u0e35\u0e40\u0e21\u0e25",//no i18n
"sentiment.criteria.count":"\u0e08\u0e33\u0e19\u0e27\u0e19",//no i18n
"sentiment.criteria.percentage":"\u0e23\u0e49\u0e2d\u0e22\u0e25\u0e30",//no i18n
"sentiment.criteria.lastmail":"\u0e2a\u0e33\u0e2b\u0e23\u0e31\u0e1a\u0e2d\u0e35\u0e40\u0e21\u0e25\u0e25\u0e48\u0e32\u0e2a\u0e38\u0e14",//no i18n
"Chats":"\u0e2a\u0e19\u0e17\u0e19\u0e32",//no i18n
"Attended":"\u0e23\u0e31\u0e1a\u0e2a\u0e32\u0e22\u0e41\u0e25\u0e49\u0e27",//no i18n
"crm.lead.prediction.popup.text":"\u0e40\u0e21\u0e37\u0e48\u0e2d\u0e21\u0e35\u0e04\u0e27\u0e32\u0e21\u0e40\u0e1b\u0e47\u0e19\u0e44\u0e1b\u0e44\u0e14\u0e49\u0e17\u0e35\u0e48\u0e08\u0e30\u0e41\u0e1b\u0e25\u0e07\u0e2d\u0e22\u0e39\u0e48\u0e17\u0e35\u0e48 {0} \u0e04\u0e30\u0e41\u0e19\u0e19\u0e01\u0e32\u0e23\u0e04\u0e32\u0e14\u0e40\u0e14\u0e32\u0e04\u0e27\u0e23\u0e2d\u0e22\u0e39\u0e48\u0e23\u0e30\u0e2b\u0e27\u0e48\u0e32\u0e07 {1}",//no i18n
"crm.lead.prediction.popup.final":"\u0e42\u0e1b\u0e23\u0e14\u0e40\u0e1b\u0e25\u0e35\u0e48\u0e22\u0e19\u0e15\u0e31\u0e27\u0e01\u0e23\u0e2d\u0e07\u0e43\u0e2b\u0e49\u0e2a\u0e2d\u0e14\u0e04\u0e25\u0e49\u0e2d\u0e07 \u0e41\u0e25\u0e30\u0e25\u0e2d\u0e07\u0e2d\u0e35\u0e01\u0e04\u0e23\u0e31\u0e49\u0e07",//no i18n
"crm.custom.field.less.than.to1":"\u0e0a\u0e48\u0e27\u0e07 \u0e08\u0e32\u0e01 \u0e15\u0e49\u0e2d\u0e07\u0e44\u0e21\u0e48\u0e21\u0e32\u0e01\u0e01\u0e27\u0e48\u0e32\u0e0a\u0e48\u0e27\u0e07 \u0e16\u0e36\u0e07",//no i18n
"Last\ Activity\ Date":"\u0e27\u0e31\u0e19\u0e17\u0e35\u0e48\u0e01\u0e34\u0e08\u0e01\u0e23\u0e23\u0e21\u0e25\u0e48\u0e32\u0e2a\u0e38\u0e14",//no i18n
"crm.label.vendor.name":"\u0e0a\u0e37\u0e48\u0e2d {0}",//no i18n
"hours":"\u0e0a\u0e31\u0e48\u0e27\u0e42\u0e21\u0e07",//no i18n
"days":"\u0e27\u0e31\u0e19",//no i18n
"weeks":"\u0e2a\u0e31\u0e1b\u0e14\u0e32\u0e2b\u0e4c",//no i18n
"months":"\u0e40\u0e14\u0e37\u0e2d\u0e19",//no i18n
"years":"\u0e1b\u0e35",//no i18n
"crm.label.general.small.after":"\u0e2b\u0e25\u0e31\u0e07",//no i18n
"Last\ Week":"\u0e2a\u0e31\u0e1b\u0e14\u0e32\u0e2b\u0e4c\u0e17\u0e35\u0e48\u0e41\u0e25\u0e49\u0e27",//no i18n
"Last\ Month":"\u0e40\u0e14\u0e37\u0e2d\u0e19\u0e17\u0e35\u0e48\u0e41\u0e25\u0e49\u0e27",//no i18n
"crm.module.name":"\u0e0a\u0e37\u0e48\u0e2d {0}",//no i18n
"Campaign":"\u0e41\u0e04\u0e21\u0e40\u0e1b\u0e0d",//no i18n
"Tasks":"\u0e07\u0e32\u0e19",//no i18n
"Calls":"\u0e01\u0e32\u0e23\u0e42\u0e17\u0e23",//no i18n
"Events":"\u0e40\u0e2b\u0e15\u0e38\u0e01\u0e32\u0e23\u0e13\u0e4c",//no i18n
"sentiment.criteria.wrongcriteria":"\u0e04\u0e48\u0e32\u0e40\u0e01\u0e13\u0e11\u0e4c\u0e44\u0e21\u0e48\u0e2a\u0e32\u0e21\u0e32\u0e23\u0e16\u0e21\u0e32\u0e01\u0e01\u0e27\u0e48\u0e32 {0}",//no i18n
"crm.chosen.minimum.input.text":"\u0e42\u0e1b\u0e23\u0e14\u0e1b\u0e49\u0e2d\u0e19\u0e2d\u0e22\u0e48\u0e32\u0e07\u0e19\u0e49\u0e2d\u0e22 {0} \u0e15\u0e31\u0e27\u0e2d\u0e31\u0e01\u0e02\u0e23\u0e30",//no i18n
"crm.intelligence.prediction.trendup":"\u0e41\u0e19\u0e27\u0e42\u0e19\u0e49\u0e21\u0e2a\u0e39\u0e07\u0e02\u0e36\u0e49\u0e19",//no i18n
"crm.intelligence.prediction.trenddown":"\u0e41\u0e19\u0e27\u0e42\u0e19\u0e49\u0e21\u0e25\u0e14\u0e25\u0e07",//no i18n
"crm.zia.prediction.notrend":"No trend",//no i18n
"crm.zia.prediction.likelihood":"Likelihood Score",//no i18n
"Completed":"\u0e40\u0e2a\u0e23\u0e47\u0e08\u0e2a\u0e21\u0e1a\u0e39\u0e23\u0e13\u0e4c" ,//no i18n
"crm.label.success":"\u0e2a\u0e33\u0e40\u0e23\u0e47\u0e08" ,//no i18n
"crm.label.Failure":"\u0e25\u0e49\u0e21\u0e40\u0e2b\u0e25\u0e27" ,//no i18n
"Both":"\u0e17\u0e31\u0e49\u0e07\u0e2a\u0e2d\u0e07" ,//no i18n
"crm.condition.cannot.empty":"\u0e40\u0e07\u0e37\u0e48\u0e2d\u0e19\u0e44\u0e02\u0e44\u0e21\u0e48\u0e2a\u0e32\u0e21\u0e32\u0e23\u0e16\u0e27\u0e48\u0e32\u0e07\u0e40\u0e1b\u0e25\u0e48\u0e32",//no i18n
"crm.condition.last.30.days":"\u0e43\u0e19\u0e0a\u0e48\u0e27\u0e07 30 \u0e27\u0e31\u0e19\u0e17\u0e35\u0e48\u0e41\u0e25\u0e49\u0e27",//no i18n
"crm.condition.last.60.days":"\u0e43\u0e19\u0e0a\u0e48\u0e27\u0e07 60 \u0e27\u0e31\u0e19\u0e17\u0e35\u0e48\u0e41\u0e25\u0e49\u0e27",//no i18n
"crm.condition.last.90.days":"\u0e43\u0e19\u0e0a\u0e48\u0e27\u0e07 90 \u0e27\u0e31\u0e19\u0e17\u0e35\u0e48\u0e41\u0e25\u0e49\u0e27",//no i18n
"crm.sentiment.Positive":"\u0e14\u0e49\u0e32\u0e19\u0e1a\u0e27\u0e01",//no i18n
"crm.sentiment.Negative":"\u0e14\u0e49\u0e32\u0e19\u0e25\u0e1a",//no i18n
"sentiment.positiveandnegative":"\u0e14\u0e49\u0e32\u0e19\u0e1a\u0e27\u0e01\u0e41\u0e25\u0e30\u0e14\u0e49\u0e32\u0e19\u0e25\u0e1a",//no i18n
"sentiment.positiveornegative":"\u0e14\u0e49\u0e32\u0e19\u0e1a\u0e27\u0e01\u0e2b\u0e23\u0e37\u0e2d\u0e14\u0e49\u0e32\u0e19\u0e25\u0e1a",//no i18n
"sentiment.positiveonly":"\u0e14\u0e49\u0e32\u0e19\u0e1a\u0e27\u0e01\u0e40\u0e17\u0e48\u0e32\u0e19\u0e31\u0e49\u0e19",//no i18n
"sentiment.negativeonly":"\u0e14\u0e49\u0e32\u0e19\u0e25\u0e1a\u0e40\u0e17\u0e48\u0e32\u0e19\u0e31\u0e49\u0e19",//no i18n
"crm.sentiment.Neutral":"\u0e40\u0e09\u0e22\u0e46",//no i18n
"crm.filters.select.campaign.type":"\u0e40\u0e25\u0e37\u0e2d\u0e01\u0e1b\u0e23\u0e30\u0e40\u0e20\u0e17 {0}",//no i18n
"crm.filters.select.campaign.status":"\u0e40\u0e25\u0e37\u0e2d\u0e01\u0e2a\u0e16\u0e32\u0e19\u0e30 {0}",//no i18n
"campaign.Member" : "\u0e2a\u0e21\u0e32\u0e0a\u0e34\u0e01",//no i18n
	"Service":"\u0e1a\u0e23\u0e34\u0e01\u0e32\u0e23",//no i18n
"Activities":"\u0e01\u0e34\u0e08\u0e01\u0e23\u0e23\u0e21",//no i18n
"crm.livedesk.pot.nextdays":"{0} \u0e27\u0e31\u0e19\u0e16\u0e31\u0e14\u0e44\u0e1b",//no i18n
"Today\ +\ Overdue":"\u0e27\u0e31\u0e19\u0e19\u0e35\u0e49 + \u0e17\u0e35\u0e48\u0e40\u0e25\u0e22\u0e01\u0e33\u0e2b\u0e19\u0e14",//no i18n
"crm.source.user.and.system":"\u0e1c\u0e39\u0e49\u0e43\u0e0a\u0e49\u0e41\u0e25\u0e30\u0e23\u0e30\u0e1a\u0e1a",//no i18n
"crm.source.user.or.system":"\u0e1c\u0e39\u0e49\u0e43\u0e0a\u0e49\u0e2b\u0e23\u0e37\u0e2d\u0e23\u0e30\u0e1a\u0e1a",//no i18n
"User":"\u0e1c\u0e39\u0e49\u0e43\u0e0a\u0e49",//no i18n
"crm.source.user.only":"\u0e40\u0e09\u0e1e\u0e32\u0e30\u0e1c\u0e39\u0e49\u0e43\u0e0a\u0e49\u0e40\u0e17\u0e48\u0e32\u0e19\u0e31\u0e49\u0e19",//no i18n
"crm.source.system.only":"\u0e42\u0e14\u0e22\u0e23\u0e30\u0e1a\u0e1a\u0e40\u0e17\u0e48\u0e32\u0e19\u0e31\u0e49\u0e19",//no i18n
"Scheduled":"\u0e01\u0e33\u0e2b\u0e19\u0e14\u0e01\u0e32\u0e23\u0e41\u0e25\u0e49\u0e27",//no i18n
"Attended\ Dialled":"\u0e01\u0e32\u0e23\u0e42\u0e17\u0e23\u0e2d\u0e2d\u0e01\u0e17\u0e35\u0e48\u0e21\u0e35\u0e04\u0e19\u0e23\u0e31\u0e1a\u0e2a\u0e32\u0e22",//no i18n
"Unattended\ Dialled":"\u0e01\u0e32\u0e23\u0e42\u0e17\u0e23\u0e2d\u0e2d\u0e01\u0e17\u0e35\u0e48\u0e44\u0e21\u0e48\u0e21\u0e35\u0e04\u0e19\u0e23\u0e31\u0e1a\u0e2a\u0e32\u0e22",//no i18n
"Cancelled":"\u0e22\u0e01\u0e40\u0e25\u0e34\u0e01\u0e41\u0e25\u0e49\u0e27",//no i18n
"crm.filter.email.isblocked":"\u0e16\u0e39\u0e01\u0e1a\u0e25\u0e47\u0e2d\u0e01",//no i18n
"crm.filter.email.isnotblocked":"\u0e44\u0e21\u0e48\u0e16\u0e39\u0e01\u0e1a\u0e25\u0e47\u0e2d\u0e01",//no i18n
"condition.till.now":"\u0e08\u0e19\u0e16\u0e36\u0e07\u0e15\u0e2d\u0e19\u0e19\u0e35\u0e49",//no i18n
"crm.recurring.no.months":"{0} \u0e40\u0e14\u0e37\u0e2d\u0e19",//no i18n
"crm.lead.prediction.tooltip":"\u0e21\u0e35\u0e04\u0e27\u0e32\u0e21\u0e40\u0e1b\u0e47\u0e19\u0e44\u0e1b\u0e44\u0e14\u0e49\u0e17\u0e35\u0e48\u0e08\u0e30\u0e41\u0e1b\u0e25\u0e07 - \u0e0a\u0e48\u0e27\u0e07\u0e04\u0e30\u0e41\u0e19\u0e19",//no i18n
"crm.website.activity":"\u0e01\u0e34\u0e08\u0e01\u0e23\u0e23\u0e21\u0e40\u0e27\u0e47\u0e1a\u0e44\u0e0b\u0e15\u0e4c",//no i18n
"crm.label.More":"\u0e40\u0e1e\u0e34\u0e48\u0e21\u0e40\u0e15\u0e34\u0e21",//no i18n
"crm.label.By":"\u0e42\u0e14\u0e22",//no i18n
"crm.chosen.searching.text":"\u0e01\u0e33\u0e25\u0e31\u0e07\u0e04\u0e49\u0e19\u0e2b\u0e32...",//no i18n
"crm.label.memberstatus.is":"\u0e41\u0e25\u0e30\u0e2a\u0e16\u0e32\u0e19\u0e30\u0e2a\u0e21\u0e32\u0e0a\u0e34\u0e01\u0e04\u0e37\u0e2d",//no i18n
"crm.events.duration":"\u0e23\u0e30\u0e22\u0e30\u0e40\u0e27\u0e25\u0e32",//no i18n
"crm.title.clear.name":"\u0e25\u0e49\u0e32\u0e07",//no i18n
"crm.label.status.is":"\u0e41\u0e25\u0e30\u0e2a\u0e16\u0e32\u0e19\u0e30\u0e04\u0e37\u0e2d",//no i18n
"zia.last3.help":"\u0e01\u0e32\u0e23\u0e2a\u0e19\u0e17\u0e19\u0e32\u0e23\u0e27\u0e21\u0e16\u0e36\u0e07\u0e01\u0e32\u0e23\u0e42\u0e17\u0e23 \u0e07\u0e32\u0e19, {0}, \u0e2d\u0e35\u0e40\u0e21\u0e25\u0e17\u0e35\u0e48\u0e44\u0e14\u0e49\u0e23\u0e31\u0e1a \u0e1a\u0e31\u0e19\u0e17\u0e36\u0e01\u0e22\u0e48\u0e2d \u0e01\u0e32\u0e23\u0e40\u0e22\u0e35\u0e48\u0e22\u0e21\u0e0a\u0e21\u0e44\u0e0b\u0e15\u0e4c \u0e02\u0e49\u0e2d\u0e04\u0e34\u0e14\u0e40\u0e2b\u0e47\u0e19\u0e1a\u0e19\u0e2a\u0e37\u0e48\u0e2d\u0e2a\u0e31\u0e07\u0e04\u0e21 \u0e04\u0e33\u0e02\u0e2d\u0e01\u0e32\u0e23\u0e2a\u0e19\u0e31\u0e1a\u0e2a\u0e19\u0e38\u0e19\u0e08\u0e32\u0e01 Desk",//no i18n
"crm.label.tag.related.to":"\u0e17\u0e35\u0e48\u0e40\u0e01\u0e35\u0e48\u0e22\u0e27\u0e02\u0e49\u0e2d\u0e07\u0e01\u0e31\u0e1a",//no i18n
"crm.alert.maximum.text.values.contains":"\u0e04\u0e38\u0e13\u0e44\u0e21\u0e48\u0e2a\u0e32\u0e21\u0e32\u0e23\u0e16\u0e1b\u0e49\u0e2d\u0e19\u0e04\u0e48\u0e32\u0e44\u0e14\u0e49\u0e21\u0e32\u0e01\u0e01\u0e27\u0e48\u0e32 {0} \u0e23\u0e32\u0e22\u0e01\u0e32\u0e23\u0e43\u0e19\u0e40\u0e02\u0e15\u0e02\u0e49\u0e2d\u0e21\u0e39\u0e25\u0e19\u0e35\u0e49",//no i18n
//filter related keys-end

//smart filter keys
"crm.lead.prediction.convert.high":"\u0e2a\u0e39\u0e07",//no i18n
"crm.lead.prediction.convert.medium":"\u0e1b\u0e32\u0e19\u0e01\u0e25\u0e32\u0e07",//no i18n
"crm.lead.prediction.convert.low":"\u0e15\u0e48\u0e33",//no i18n
"crm.lead.prediction.focus.nofollowup":"\u0e44\u0e21\u0e48\u0e21\u0e35\u0e01\u0e32\u0e23\u0e15\u0e34\u0e14\u0e15\u0e32\u0e21\u0e16\u0e31\u0e14\u0e44\u0e1b",//no i18n
"crm.lead.prediction.likely.convert":"\u0e21\u0e35\u0e04\u0e27\u0e32\u0e21\u0e40\u0e1b\u0e47\u0e19\u0e44\u0e1b\u0e44\u0e14\u0e49\u0e17\u0e35\u0e48\u0e08\u0e30\u0e41\u0e1b\u0e25\u0e07",//no i18n
"crm.lead.prediction.recent.score":"\u0e04\u0e30\u0e41\u0e19\u0e19\u0e01\u0e32\u0e23\u0e04\u0e32\u0e14\u0e40\u0e14\u0e32\u0e25\u0e48\u0e32\u0e2a\u0e38\u0e14",//no i18n
"crm.lead.prediction.tooltip":"\u0e21\u0e35\u0e04\u0e27\u0e32\u0e21\u0e40\u0e1b\u0e47\u0e19\u0e44\u0e1b\u0e44\u0e14\u0e49\u0e17\u0e35\u0e48\u0e08\u0e30\u0e41\u0e1b\u0e25\u0e07 - \u0e0a\u0e48\u0e27\u0e07\u0e04\u0e30\u0e41\u0e19\u0e19",//no i18n
"crm.lead.prediction.tooltip.convert":"\u0e04\u0e27\u0e32\u0e21\u0e40\u0e1b\u0e47\u0e19\u0e44\u0e1b\u0e44\u0e14\u0e49",//no i18n
"crm.lead.prediction.tooltip.score":"\u0e04\u0e30\u0e41\u0e19\u0e19",//no i18n
"Planned":"\u0e27\u0e32\u0e07\u0e41\u0e1c\u0e19\u0e41\u0e25\u0e49\u0e27",//no i18n
"Planned":"\u0e27\u0e32\u0e07\u0e41\u0e1c\u0e19\u0e41\u0e25\u0e49\u0e27",//no i18n
"Invited":"\u0e44\u0e14\u0e49\u0e40\u0e0a\u0e34\u0e0d",//no i18n
"Sent":"\u0e2a\u0e48\u0e07\u0e41\u0e25\u0e49\u0e27",//no i18n
"Failed":"\u0e25\u0e49\u0e21\u0e40\u0e2b\u0e25\u0e27",//no i18n
"Opened":"\u0e40\u0e1b\u0e34\u0e14\u0e41\u0e25\u0e49\u0e27",//no i18n
"Bounced":"\u0e15\u0e35\u0e01\u0e25\u0e31\u0e1a\u0e41\u0e25\u0e49\u0e27",//no i18n
"Responded":"\u0e15\u0e2d\u0e1a\u0e2a\u0e19\u0e2d\u0e07\u0e41\u0e25\u0e49\u0e27",//no i18n
"Opted\ Out":"\u0e40\u0e25\u0e37\u0e2d\u0e01\u0e44\u0e21\u0e48\u0e23\u0e31\u0e1a\u0e41\u0e25\u0e49\u0e27",//no i18n
"Clicked":"\u0e04\u0e25\u0e34\u0e01\u0e41\u0e25\u0e49\u0e27",//no i18n
"Marked.as.spam":"\u0e17\u0e33\u0e40\u0e04\u0e23\u0e37\u0e48\u0e2d\u0e07\u0e2b\u0e21\u0e32\u0e22\u0e27\u0e48\u0e32\u0e40\u0e1b\u0e47\u0e19\u0e2a\u0e41\u0e1b\u0e21",//no i18n
"Member\ Status":"\u0e2a\u0e16\u0e32\u0e19\u0e30\u0e2a\u0e21\u0e32\u0e0a\u0e34\u0e01",//no i18n
"Camp\ Member\ Status":"\u0e2a\u0e16\u0e32\u0e19\u0e30\u0e2a\u0e21\u0e32\u0e0a\u0e34\u0e01\u0e41\u0e04\u0e21\u0e40\u0e1b\u0e0d",//no i18n
"Campaign\ Member":"\u0e2a\u0e21\u0e32\u0e0a\u0e34\u0e01\u0e41\u0e04\u0e21\u0e40\u0e1b\u0e0d",//no i18n
"crm.zinvoice.dueIn":"\u0e04\u0e23\u0e1a\u0e01\u0e33\u0e2b\u0e19\u0e14\u0e43\u0e19",//no i18n
"crm.zinvoice.dueby":"\u0e04\u0e23\u0e1a\u0e01\u0e33\u0e2b\u0e19\u0e14\u0e20\u0e32\u0e22\u0e43\u0e19",//no i18n
"Rollbacked":"\u0e22\u0e49\u0e2d\u0e19\u0e01\u0e25\u0e31\u0e1a\u0e41\u0e25\u0e49\u0e27",//no i18n
"crm.filter.label.with.open":"\u0e21\u0e35 {0} \u0e17\u0e35\u0e48\u0e40\u0e1b\u0e34\u0e14\u0e2d\u0e22\u0e39\u0e48",//no i18n
"crm.filter.label.with.module":"\u0e21\u0e35 {0}",//no i18n
"crm.filter.label.activity.due":"{0} \u0e04\u0e23\u0e1a\u0e01\u0e33\u0e2b\u0e19\u0e14",//no i18n
"crm.filter.label.activity.done":"{0} \u0e17\u0e33\u0e41\u0e25\u0e49\u0e27",//no i18n
"Date":"\u0e27\u0e31\u0e19\u0e17\u0e35\u0e48",//no i18n
"crm.filter.label.notes.added":"\u0e40\u0e1e\u0e34\u0e48\u0e21\u0e1a\u0e31\u0e19\u0e17\u0e36\u0e01\u0e22\u0e48\u0e2d\u0e41\u0e25\u0e49\u0e27",//no i18n
"crm.label.filter.email.status":"\u0e2a\u0e16\u0e32\u0e19\u0e30\u0e2d\u0e35\u0e40\u0e21\u0e25",//no i18n
"crm.filter.label.sent":"\u0e2a\u0e48\u0e07\u0e41\u0e25\u0e49\u0e27",//no i18n
"crm.filter.label.not.sent":"\u0e44\u0e21\u0e48\u0e44\u0e14\u0e49\u0e2a\u0e48\u0e07",//no i18n
"crm.filter.label.opened":"\u0e40\u0e1b\u0e34\u0e14\u0e41\u0e25\u0e49\u0e27",//no i18n
"crm.filter.label.not.opened":"\u0e44\u0e21\u0e48\u0e44\u0e14\u0e40\u0e1b\u0e34\u0e14",//no i18n
"crm.filter.label.received":"\u0e44\u0e14\u0e49\u0e23\u0e31\u0e1a\u0e41\u0e25\u0e49\u0e27",//no i18n
"crm.filter.label.not.received":"\u0e44\u0e21\u0e48\u0e44\u0e14\u0e49\u0e23\u0e31\u0e1a",//no i18n
"crm.filter.label.bounced":"\u0e15\u0e35\u0e01\u0e25\u0e31\u0e1a\u0e41\u0e25\u0e49\u0e27",//no i18n
"crm.filter.label.opened.not.replied":"\u0e40\u0e1b\u0e34\u0e14\u0e41\u0e25\u0e49\u0e27\u0e41\u0e25\u0e30\u0e44\u0e21\u0e48\u0e44\u0e14\u0e49\u0e15\u0e2d\u0e1a\u0e01\u0e25\u0e31\u0e1a",//no i18n
"crm.filter.label.any":"\u0e23\u0e32\u0e22\u0e01\u0e32\u0e23\u0e2b\u0e19\u0e36\u0e48\u0e07\u0e43\u0e14\u0e02\u0e49\u0e32\u0e07\u0e15\u0e49\u0e19",//no i18n
"crm.zia.config.potential.amount":"\u0e08\u0e33\u0e19\u0e27\u0e19\u0e40\u0e07\u0e34\u0e19 {0}",//no i18n
"Quote\ Stage":"\u0e02\u0e31\u0e49\u0e19\u0e15\u0e2d\u0e19\u0e17\u0e35\u0e48 {0}",//no i18n
"crm.module.owner":"\u0e40\u0e08\u0e49\u0e32\u0e02\u0e2d\u0e07 {0}",//no i18n
"crm.label.Potential\ Closing\ Date":"\u0e27\u0e31\u0e19\u0e17\u0e35\u0e48\u0e1b\u0e34\u0e14\u0e1c\u0e39\u0e49\u0e17\u0e35\u0e48\u0e21\u0e35\u0e28\u0e31\u0e01\u0e22\u0e20\u0e32\u0e1e",//no i18n
"crm.lead.prediction.likely.convert":"\u0e21\u0e35\u0e04\u0e27\u0e32\u0e21\u0e40\u0e1b\u0e47\u0e19\u0e44\u0e1b\u0e44\u0e14\u0e49\u0e17\u0e35\u0e48\u0e08\u0e30\u0e41\u0e1b\u0e25\u0e07",//no i18n
"crm.intelligence.prediction.score":"\u0e04\u0e30\u0e41\u0e19\u0e19\u0e01\u0e32\u0e23\u0e04\u0e32\u0e14\u0e40\u0e14\u0e32",//no i18n
"crm.lead.prediction.recent.score":"\u0e04\u0e30\u0e41\u0e19\u0e19\u0e01\u0e32\u0e23\u0e04\u0e32\u0e14\u0e40\u0e14\u0e32\u0e25\u0e48\u0e32\u0e2a\u0e38\u0e14",//no i18n
"crm.intelligence.prediction.lastconv":"\u0e01\u0e32\u0e23\u0e41\u0e1b\u0e25\u0e07 3 \u0e23\u0e32\u0e22\u0e01\u0e32\u0e23\u0e25\u0e48\u0e32\u0e2a\u0e38\u0e14",//no i18n
"crm.intelligence.prediction.recordsfocus":"\u0e23\u0e30\u0e40\u0e1a\u0e35\u0e22\u0e19\u0e17\u0e35\u0e48\u0e08\u0e30\u0e21\u0e38\u0e48\u0e07\u0e40\u0e19\u0e49\u0e19",//no i18n
"crm.intelligence.prediction.slowmoving":"\u0e01\u0e32\u0e23\u0e40\u0e04\u0e25\u0e37\u0e48\u0e2d\u0e19\u0e44\u0e2b\u0e27\u0e0a\u0e49\u0e32",//no i18n
"crm.intelligence.prediction.trend.down":"\u0e41\u0e19\u0e27\u0e42\u0e19\u0e49\u0e21\u0e25\u0e14\u0e25\u0e07\u0e25\u0e48\u0e32\u0e2a\u0e38\u0e14",//no i18n
"crm.label.touched.records":"\u0e23\u0e30\u0e40\u0e1a\u0e35\u0e22\u0e19\u0e17\u0e35\u0e48\u0e15\u0e34\u0e14\u0e15\u0e48\u0e2d\u0e41\u0e25\u0e49\u0e27",//no i18n
"crm.label.untouched.records":"\u0e23\u0e30\u0e40\u0e1a\u0e35\u0e22\u0e19\u0e17\u0e35\u0e48\u0e22\u0e31\u0e07\u0e44\u0e21\u0e48\u0e15\u0e34\u0e14\u0e15\u0e48\u0e2d",//no i18n
"crm.label.record.action":"\u0e01\u0e32\u0e23\u0e14\u0e33\u0e40\u0e19\u0e34\u0e19\u0e01\u0e32\u0e23\u0e01\u0e31\u0e1a\u0e23\u0e30\u0e40\u0e1a\u0e35\u0e22\u0e19",//no i18n
"workflow.rule.view.label.Modified":"\u0e41\u0e01\u0e49\u0e44\u0e02\u0e41\u0e25\u0e49\u0e27",//no i18n
"crm.label.not.modified":"\u0e22\u0e31\u0e07\u0e44\u0e21\u0e48\u0e44\u0e14\u0e49\u0e41\u0e01\u0e49\u0e44\u0e02",//no i18n
"crm.label.related.records.action":"\u0e01\u0e32\u0e23\u0e14\u0e33\u0e40\u0e19\u0e34\u0e19\u0e01\u0e32\u0e23\u0e01\u0e31\u0e1a\u0e23\u0e30\u0e40\u0e1a\u0e35\u0e22\u0e19\u0e17\u0e35\u0e48\u0e40\u0e01\u0e35\u0e48\u0e22\u0e27\u0e02\u0e49\u0e2d\u0e07",//no i18n
"Done":"\u0e40\u0e2a\u0e23\u0e47\u0e08\u0e2a\u0e34\u0e49\u0e19",//no i18n
"crm.label.not.done":"\u0e22\u0e31\u0e07\u0e44\u0e21\u0e48\u0e17\u0e33",//no i18n
"sentiment.model":"\u0e04\u0e27\u0e32\u0e21\u0e04\u0e34\u0e14\u0e40\u0e2b\u0e47\u0e19\u0e02\u0e2d\u0e07\u0e2d\u0e35\u0e40\u0e21\u0e25",//no i18n
"sentiment.criteria.count":"\u0e08\u0e33\u0e19\u0e27\u0e19",//no i18n
"sentiment.criteria.percentage":"\u0e23\u0e49\u0e2d\u0e22\u0e25\u0e30",//no i18n
"sentiment.criteria.lastmail":"\u0e2a\u0e33\u0e2b\u0e23\u0e31\u0e1a\u0e2d\u0e35\u0e40\u0e21\u0e25\u0e25\u0e48\u0e32\u0e2a\u0e38\u0e14",//no i18n
"Chats":"\u0e2a\u0e19\u0e17\u0e19\u0e32",//no i18n
"Attended":"\u0e23\u0e31\u0e1a\u0e2a\u0e32\u0e22\u0e41\u0e25\u0e49\u0e27",//no i18n
"crm.warning.select.filter":"\u0e40\u0e25\u0e37\u0e2d\u0e01\u0e2d\u0e22\u0e48\u0e32\u0e07\u0e19\u0e49\u0e2d\u0e22\u0e2b\u0e19\u0e36\u0e48\u0e07\u0e40\u0e02\u0e15\u0e02\u0e49\u0e2d\u0e21\u0e39\u0e25\u0e17\u0e35\u0e48\u0e08\u0e30\u0e01\u0e23\u0e2d\u0e07",//no i18n
"crm.lead.prediction.popup.text":"\u0e40\u0e21\u0e37\u0e48\u0e2d\u0e21\u0e35\u0e04\u0e27\u0e32\u0e21\u0e40\u0e1b\u0e47\u0e19\u0e44\u0e1b\u0e44\u0e14\u0e49\u0e17\u0e35\u0e48\u0e08\u0e30\u0e41\u0e1b\u0e25\u0e07\u0e2d\u0e22\u0e39\u0e48\u0e17\u0e35\u0e48 {0} \u0e04\u0e30\u0e41\u0e19\u0e19\u0e01\u0e32\u0e23\u0e04\u0e32\u0e14\u0e40\u0e14\u0e32\u0e04\u0e27\u0e23\u0e2d\u0e22\u0e39\u0e48\u0e23\u0e30\u0e2b\u0e27\u0e48\u0e32\u0e07 {1}",//no i18n
"crm.lead.prediction.popup.final":"\u0e42\u0e1b\u0e23\u0e14\u0e40\u0e1b\u0e25\u0e35\u0e48\u0e22\u0e19\u0e15\u0e31\u0e27\u0e01\u0e23\u0e2d\u0e07\u0e43\u0e2b\u0e49\u0e2a\u0e2d\u0e14\u0e04\u0e25\u0e49\u0e2d\u0e07 \u0e41\u0e25\u0e30\u0e25\u0e2d\u0e07\u0e2d\u0e35\u0e01\u0e04\u0e23\u0e31\u0e49\u0e07",//no i18n
"crm.custom.field.less.than.to":"\u0e0a\u0e48\u0e27\u0e07 <i>\u0e08\u0e32\u0e01</i> \u0e15\u0e49\u0e2d\u0e07\u0e44\u0e21\u0e48\u0e21\u0e32\u0e01\u0e01\u0e27\u0e48\u0e32\u0e0a\u0e48\u0e27\u0e07 <i>\u0e16\u0e36\u0e07</i>",//no i18n
"Last\ Activity\ Date":"\u0e27\u0e31\u0e19\u0e17\u0e35\u0e48\u0e01\u0e34\u0e08\u0e01\u0e23\u0e23\u0e21\u0e25\u0e48\u0e32\u0e2a\u0e38\u0e14",//no i18n
"Contact\ Name":"\u0e0a\u0e37\u0e48\u0e2d {0}",//no i18n
"crm.lead.prediction.tooltip":"\u0e21\u0e35\u0e04\u0e27\u0e32\u0e21\u0e40\u0e1b\u0e47\u0e19\u0e44\u0e1b\u0e44\u0e14\u0e49\u0e17\u0e35\u0e48\u0e08\u0e30\u0e41\u0e1b\u0e25\u0e07 - \u0e0a\u0e48\u0e27\u0e07\u0e04\u0e30\u0e41\u0e19\u0e19",//no i18n
"crm.website.activity":"\u0e01\u0e34\u0e08\u0e01\u0e23\u0e23\u0e21\u0e40\u0e27\u0e47\u0e1a\u0e44\u0e0b\u0e15\u0e4c",//no i18n
"days":"\u0e27\u0e31\u0e19",//no i18n
"weeks":"\u0e2a\u0e31\u0e1b\u0e14\u0e32\u0e2b\u0e4c",//no i18n
"months":"\u0e40\u0e14\u0e37\u0e2d\u0e19",//no i18n
"crm.condition.in.last":"\u0e43\u0e19\u0e04\u0e23\u0e31\u0e49\u0e07\u0e25\u0e48\u0e32\u0e2a\u0e38\u0e14",//no i18n
"before":"\u0e01\u0e48\u0e2d\u0e19",//no i18n
"crm.label.general.small.after":"\u0e2b\u0e25\u0e31\u0e07",//no i18n
"between":"\u0e23\u0e30\u0e2b\u0e27\u0e48\u0e32\u0e07",//no i18n
"Today":"\u0e27\u0e31\u0e19\u0e19\u0e35\u0e49",//no i18n
"Yesterday":"\u0e40\u0e21\u0e37\u0e48\u0e2d\u0e27\u0e32\u0e19",//no i18n
"crm.thisweek":"\u0e2a\u0e31\u0e1b\u0e14\u0e32\u0e2b\u0e4c\u0e19\u0e35\u0e49",//no i18n
"crm.label.this.month":"\u0e40\u0e14\u0e37\u0e2d\u0e19\u0e19\u0e35\u0e49",//no i18n
"crm.thisyear":"\u0e1b\u0e35\u0e19\u0e35\u0e49",//no i18n
"Last\ Week":"\u0e2a\u0e31\u0e1b\u0e14\u0e32\u0e2b\u0e4c\u0e17\u0e35\u0e48\u0e41\u0e25\u0e49\u0e27",//no i18n
"Last\ Month":"\u0e40\u0e14\u0e37\u0e2d\u0e19\u0e17\u0e35\u0e48\u0e41\u0e25\u0e49\u0e27",//no i18n
"crm.intelligence.prediction.trendup":"\u0e41\u0e19\u0e27\u0e42\u0e19\u0e49\u0e21\u0e2a\u0e39\u0e07\u0e02\u0e36\u0e49\u0e19",//no i18n
"crm.intelligence.prediction.trenddown":"\u0e41\u0e19\u0e27\u0e42\u0e19\u0e49\u0e21\u0e25\u0e14\u0e25\u0e07",//no i18n
"crm.condition.last.30.days":"\u0e43\u0e19\u0e0a\u0e48\u0e27\u0e07 30 \u0e27\u0e31\u0e19\u0e17\u0e35\u0e48\u0e41\u0e25\u0e49\u0e27",//no i18n
"crm.condition.last.60.days":"\u0e43\u0e19\u0e0a\u0e48\u0e27\u0e07 60 \u0e27\u0e31\u0e19\u0e17\u0e35\u0e48\u0e41\u0e25\u0e49\u0e27",//no i18n
"crm.condition.last.90.days":"\u0e43\u0e19\u0e0a\u0e48\u0e27\u0e07 90 \u0e27\u0e31\u0e19\u0e17\u0e35\u0e48\u0e41\u0e25\u0e49\u0e27",//no i18n
"crm.condition.until.now":"\u0e08\u0e19\u0e16\u0e36\u0e07\u0e15\u0e2d\u0e19\u0e19\u0e35\u0e49",//no i18n
"crm.sentiment.Positive":"\u0e14\u0e49\u0e32\u0e19\u0e1a\u0e27\u0e01",//no i18n
"crm.sentiment.Negative":"\u0e14\u0e49\u0e32\u0e19\u0e25\u0e1a",//no i18n
"sentiment.positiveandnegative":"\u0e14\u0e49\u0e32\u0e19\u0e1a\u0e27\u0e01\u0e41\u0e25\u0e30\u0e14\u0e49\u0e32\u0e19\u0e25\u0e1a",//no i18n
"sentiment.positiveornegative":"\u0e14\u0e49\u0e32\u0e19\u0e1a\u0e27\u0e01\u0e2b\u0e23\u0e37\u0e2d\u0e14\u0e49\u0e32\u0e19\u0e25\u0e1a",//no i18n
"sentiment.positiveonly":"\u0e14\u0e49\u0e32\u0e19\u0e1a\u0e27\u0e01\u0e40\u0e17\u0e48\u0e32\u0e19\u0e31\u0e49\u0e19",//no i18n
"sentiment.negativeonly":"\u0e14\u0e49\u0e32\u0e19\u0e25\u0e1a\u0e40\u0e17\u0e48\u0e32\u0e19\u0e31\u0e49\u0e19",//no i18n
"crm.sentiment.Neutral":"\u0e40\u0e09\u0e22\u0e46",//no i18n
"crm.module.name":"\u0e0a\u0e37\u0e48\u0e2d {0}",//no i18n
"Campaign":"\u0e41\u0e04\u0e21\u0e40\u0e1b\u0e0d",//no i18n
"crm.filters.select.campaign.type":"\u0e40\u0e25\u0e37\u0e2d\u0e01\u0e1b\u0e23\u0e30\u0e40\u0e20\u0e17 {0}",//no i18n
"Advertisement":"\u0e01\u0e32\u0e23\u0e42\u0e06\u0e29\u0e13\u0e32",//no i18n
"Banner\ Ads":"\u0e42\u0e06\u0e29\u0e13\u0e32\u0e1a\u0e19\u0e41\u0e1a\u0e19\u0e40\u0e19\u0e2d\u0e23\u0e4c",//no i18n
"Conference":"\u0e01\u0e32\u0e23\u0e1b\u0e23\u0e30\u0e0a\u0e38\u0e21",//no i18n
"Direct\ mail":"\u0e2d\u0e35\u0e40\u0e21\u0e25\u0e17\u0e32\u0e07\u0e15\u0e23\u0e07",//no i18n
"crm.taskreminder.line8":"\u0e2d\u0e35\u0e40\u0e21\u0e25",//no i18n
"Others":"\u0e2d\u0e37\u0e48\u0e19\u0e46",//no i18n
"Partner":"\u0e1e\u0e32\u0e23\u0e4c\u0e17\u0e40\u0e19\u0e2d\u0e23\u0e4c",//no i18n
"Public\ Relations":"\u0e1b\u0e23\u0e30\u0e0a\u0e32\u0e2a\u0e31\u0e21\u0e1e\u0e31\u0e19\u0e18\u0e4c",//no i18n
"Referral\ Program":"\u0e42\u0e1b\u0e23\u0e41\u0e01\u0e23\u0e21\u0e1c\u0e39\u0e49\u0e41\u0e19\u0e30\u0e19\u0e33",//no i18n
"Telemarketing":"\u0e01\u0e32\u0e23\u0e15\u0e25\u0e32\u0e14\u0e17\u0e32\u0e07\u0e42\u0e17\u0e23\u0e28\u0e31\u0e1e\u0e17\u0e4c",//no i18n
"Trade\ Show":"\u0e01\u0e32\u0e23\u0e41\u0e2a\u0e14\u0e07\u0e2a\u0e34\u0e19\u0e04\u0e49\u0e32",//no i18n
"Webinar":"\u0e01\u0e32\u0e23\u0e2a\u0e31\u0e21\u0e21\u0e19\u0e32\u0e17\u0e32\u0e07\u0e40\u0e27\u0e47\u0e1a",//no i18n
"crm.filters.select.campaign.status":"\u0e40\u0e25\u0e37\u0e2d\u0e01\u0e2a\u0e16\u0e32\u0e19\u0e30 {0}",//no i18n
"Active":"\u0e40\u0e1b\u0e34\u0e14\u0e17\u0e33\u0e07\u0e32\u0e19\u0e2d\u0e22\u0e39\u0e48",//no i18n
"Complete":"\u0e40\u0e2a\u0e23\u0e47\u0e08\u0e2a\u0e21\u0e1a\u0e39\u0e23\u0e13\u0e4c",//no i18n
"Inactive":"\u0e44\u0e21\u0e48\u0e44\u0e14\u0e49\u0e40\u0e1b\u0e34\u0e14\u0e43\u0e0a\u0e49\u0e07\u0e32\u0e19",//no i18n
"Planning":"\u0e01\u0e32\u0e23\u0e27\u0e32\u0e07\u0e41\u0e1c\u0e19",//no i18n
"Activities":"\u0e01\u0e34\u0e08\u0e01\u0e23\u0e23\u0e21",//no i18n
"Tasks":"\u0e07\u0e32\u0e19",//no i18n
"Calls":"\u0e01\u0e32\u0e23\u0e42\u0e17\u0e23",//no i18n
"Tomorrow":"\u0e27\u0e31\u0e19\u0e1e\u0e23\u0e38\u0e48\u0e07\u0e19\u0e35\u0e49",//no i18n
"crm.livedesk.pot.nextdays":"{0} \u0e27\u0e31\u0e19\u0e16\u0e31\u0e14\u0e44\u0e1b",//no i18n
"Today\ +\ Overdue":"\u0e27\u0e31\u0e19\u0e19\u0e35\u0e49 + \u0e17\u0e35\u0e48\u0e40\u0e25\u0e22\u0e01\u0e33\u0e2b\u0e19\u0e14",//no i18n
"crm.source.user.and.system":"\u0e1c\u0e39\u0e49\u0e43\u0e0a\u0e49\u0e41\u0e25\u0e30\u0e23\u0e30\u0e1a\u0e1a",//no i18n
"crm.source.user.or.system":"\u0e1c\u0e39\u0e49\u0e43\u0e0a\u0e49\u0e2b\u0e23\u0e37\u0e2d\u0e23\u0e30\u0e1a\u0e1a",//no i18n
"User":"\u0e1c\u0e39\u0e49\u0e43\u0e0a\u0e49",//no i18n
"crm.source.user.only":"\u0e40\u0e09\u0e1e\u0e32\u0e30\u0e1c\u0e39\u0e49\u0e43\u0e0a\u0e49\u0e40\u0e17\u0e48\u0e32\u0e19\u0e31\u0e49\u0e19",//no i18n
"crm.source.system.only":"\u0e42\u0e14\u0e22\u0e23\u0e30\u0e1a\u0e1a\u0e40\u0e17\u0e48\u0e32\u0e19\u0e31\u0e49\u0e19",//no i18n
"Scheduled":"\u0e01\u0e33\u0e2b\u0e19\u0e14\u0e01\u0e32\u0e23\u0e41\u0e25\u0e49\u0e27",//no i18n
"Attended\ Dialled":"\u0e01\u0e32\u0e23\u0e42\u0e17\u0e23\u0e2d\u0e2d\u0e01\u0e17\u0e35\u0e48\u0e21\u0e35\u0e04\u0e19\u0e23\u0e31\u0e1a\u0e2a\u0e32\u0e22",//no i18n
"Unattended\ Dialled":"\u0e01\u0e32\u0e23\u0e42\u0e17\u0e23\u0e2d\u0e2d\u0e01\u0e17\u0e35\u0e48\u0e44\u0e21\u0e48\u0e21\u0e35\u0e04\u0e19\u0e23\u0e31\u0e1a\u0e2a\u0e32\u0e22",//no i18n
"Cancelled":"\u0e22\u0e01\u0e40\u0e25\u0e34\u0e01\u0e41\u0e25\u0e49\u0e27",//no i18n
"crm.filter.email.isblocked":"\u0e16\u0e39\u0e01\u0e1a\u0e25\u0e47\u0e2d\u0e01",//no i18n
"crm.filter.email.isnotblocked":"\u0e44\u0e21\u0e48\u0e16\u0e39\u0e01\u0e1a\u0e25\u0e47\u0e2d\u0e01",//no i18n
"crm.condition.till.today":"\u0e08\u0e19\u0e16\u0e36\u0e07\u0e27\u0e31\u0e19\u0e19\u0e35\u0e49",//no i18n
"crm.recurring.no.months":"{0} \u0e40\u0e14\u0e37\u0e2d\u0e19",//no i18n
"sentiment.criteria.wrongcriteria":"\u0e04\u0e48\u0e32\u0e40\u0e01\u0e13\u0e11\u0e4c\u0e44\u0e21\u0e48\u0e2a\u0e32\u0e21\u0e32\u0e23\u0e16\u0e21\u0e32\u0e01\u0e01\u0e27\u0e48\u0e32 {0}",//no i18n
"crm.chosen.minimum.input.text":"\u0e42\u0e1b\u0e23\u0e14\u0e1b\u0e49\u0e2d\u0e19\u0e2d\u0e22\u0e48\u0e32\u0e07\u0e19\u0e49\u0e2d\u0e22 {0} \u0e15\u0e31\u0e27\u0e2d\u0e31\u0e01\u0e02\u0e23\u0e30",//no i18n
"crm.chosen.searching.text":"\u0e01\u0e33\u0e25\u0e31\u0e07\u0e04\u0e49\u0e19\u0e2b\u0e32...",//no i18n
"is":"\u0e40\u0e1b\u0e47\u0e19",//no i18n
"crm.label.memberstatus.is":"\u0e41\u0e25\u0e30\u0e2a\u0e16\u0e32\u0e19\u0e30\u0e2a\u0e21\u0e32\u0e0a\u0e34\u0e01\u0e04\u0e37\u0e2d",//no i18n
"crm.events.duration":"\u0e23\u0e30\u0e22\u0e30\u0e40\u0e27\u0e25\u0e32",//no i18n
"and":"\u0e41\u0e25\u0e30",//no i18n
"crm.title.clear.name":"\u0e25\u0e49\u0e32\u0e07",//no i18n
"zia.last3.help":"\u0e01\u0e32\u0e23\u0e2a\u0e19\u0e17\u0e19\u0e32\u0e23\u0e27\u0e21\u0e16\u0e36\u0e07\u0e01\u0e32\u0e23\u0e42\u0e17\u0e23 \u0e07\u0e32\u0e19, {0}, \u0e2d\u0e35\u0e40\u0e21\u0e25\u0e17\u0e35\u0e48\u0e44\u0e14\u0e49\u0e23\u0e31\u0e1a \u0e1a\u0e31\u0e19\u0e17\u0e36\u0e01\u0e22\u0e48\u0e2d \u0e01\u0e32\u0e23\u0e40\u0e22\u0e35\u0e48\u0e22\u0e21\u0e0a\u0e21\u0e44\u0e0b\u0e15\u0e4c \u0e02\u0e49\u0e2d\u0e04\u0e34\u0e14\u0e40\u0e2b\u0e47\u0e19\u0e1a\u0e19\u0e2a\u0e37\u0e48\u0e2d\u0e2a\u0e31\u0e07\u0e04\u0e21 \u0e04\u0e33\u0e02\u0e2d\u0e01\u0e32\u0e23\u0e2a\u0e19\u0e31\u0e1a\u0e2a\u0e19\u0e38\u0e19\u0e08\u0e32\u0e01 Desk",//no i18n
"crm.label.By":"\u0e42\u0e14\u0e22",//no i18n
"crm.label.add.note":"\u0e40\u0e1e\u0e34\u0e48\u0e21\u0e1a\u0e31\u0e19\u0e17\u0e36\u0e01\u0e22\u0e48\u0e2d",//no i18n
"crm.label.simply.by":"\u0e42\u0e14\u0e22",//no i18n
"crm.general.addnote":"\u0e40\u0e1e\u0e34\u0e48\u0e21\u0e1a\u0e31\u0e19\u0e17\u0e36\u0e01\u0e22\u0e48\u0e2d",//no i18n
"crm.general.addtitle":"\u0e40\u0e1e\u0e34\u0e48\u0e21\u0e0a\u0e37\u0e48\u0e2d\u0e40\u0e23\u0e37\u0e48\u0e2d\u0e07",//no i18n
"crm.label.attach.file":"\u0e41\u0e19\u0e1a\u0e44\u0e1f\u0e25\u0e4c",//no i18n
"crm.button.mass.delete":"\u0e25\u0e1a",//no i18n
"crm.warning.delete.record":"\u0e04\u0e38\u0e13\u0e41\u0e19\u0e48\u0e43\u0e08\u0e2b\u0e23\u0e37\u0e2d\u0e27\u0e48\u0e32\u0e04\u0e38\u0e13\u0e15\u0e49\u0e2d\u0e07\u0e01\u0e32\u0e23\u0e25\u0e1a \"{0}\"",//no i18n
"crm.label.yes":"\u0e43\u0e0a\u0e48",//no i18n
"crm.note.view.previous":"\u0e14\u0e39\u0e1a\u0e31\u0e19\u0e17\u0e36\u0e01\u0e22\u0e48\u0e2d\u0e01\u0e48\u0e2d\u0e19\u0e2b\u0e19\u0e49\u0e32",//no i18n
"of":"\u0e02\u0e2d\u0e07",//no i18n
"crm.label.notes":"\u0e1a\u0e31\u0e19\u0e17\u0e36\u0e01\u0e22\u0e48\u0e2d",//no i18n
"crm.note.recent.first":"\u0e25\u0e33\u0e14\u0e31\u0e1a\u0e41\u0e23\u0e01\u0e25\u0e48\u0e32\u0e2a\u0e38\u0e14",//no i18n
"crm.note.recent.last":"\u0e25\u0e33\u0e14\u0e31\u0e1a\u0e2a\u0e38\u0e14\u0e17\u0e49\u0e32\u0e22\u0e25\u0e48\u0e32\u0e2a\u0e38\u0e14",//no i18n
"crm.territory.label.only":"{0} \u0e40\u0e17\u0e48\u0e32\u0e19\u0e31\u0e49\u0e19",//no i18n
"crm.button.apply.filter":"\u0e43\u0e0a\u0e49\u0e07\u0e32\u0e19\u0e15\u0e31\u0e27\u0e01\u0e23\u0e2d\u0e07",//no i18n
"PM":"PM",//no i18n
"crm.mb.newversion.msg4":"\u0e15\u0e01\u0e25\u0e07 \u0e40\u0e02\u0e49\u0e32\u0e43\u0e08\u0e41\u0e25\u0e49\u0e27",//no i18n
"Jan":"\u0e21.\u0e04.",//no i18n
"Feb":"\u0e01.\u0e1e.",//no i18n
"Mar":"\u0e21\u0e35.\u0e04.",//no i18n
"Apr":"\u0e40\u0e21.\u0e22.",//no i18n
"Jun":"\u0e21\u0e34.\u0e22.",//no i18n
"Jul":"\u0e01.\u0e04.",//no i18n
"Aug":"\u0e2a.\u0e04.",//no i18n
"Sep":"\u0e01.\u0e22.",//no i18n
"Oct":"\u0e15.\u0e04.",//no i18n
"Nov":"\u0e1e.\u0e22.",//no i18n
"Dec":"\u0e18.\u0e04.",//no i18n
"crm.label.unmapped.stages":"\u0e22\u0e31\u0e07\u0e44\u0e21\u0e48\u0e21\u0e35\u0e1a\u0e31\u0e0d\u0e0a\u0e35",//no i18n
"crm.wf.summary.label.ShowInstantActions":"\u0e41\u0e2a\u0e14\u0e07\u0e40\u0e1e\u0e34\u0e48\u0e21\u0e40\u0e15\u0e34\u0e21",//no i18n
"crm.wf.summary.label.HideInstantActions":"\u0e41\u0e2a\u0e14\u0e07\u0e19\u0e49\u0e2d\u0e22\u0e25\u0e07",//no i18n
"crm.label.account.created":"{0} \u0e43\u0e2b\u0e21\u0e48\u0e08\u0e30\u0e16\u0e39\u0e01\u0e2a\u0e23\u0e49\u0e32\u0e07",//no i18n
"crm.krp.no.records.found":"\u0e44\u0e21\u0e48\u0e1e\u0e1a {0}",//no i18n
"crm.module.new":"\u0e2a\u0e23\u0e49\u0e32\u0e07 {0}",//no i18n
"crm.label.view":"\u0e14\u0e39",//no i18n
"crm.nsocial.customers":"\u0e25\u0e39\u0e01\u0e04\u0e49\u0e32",//no i18n
"crm.nsocial.open.potential":"{0} \u0e17\u0e35\u0e48\u0e40\u0e1b\u0e34\u0e14\u0e2d\u0e22\u0e39\u0e48",//no i18n
"crm.nsocial.lead.contact":"{0}/{1}",//no i18n
"Others":"\u0e2d\u0e37\u0e48\u0e19\u0e46",//no i18n
"crm.field.length.check":"\u0e04\u0e48\u0e32 {0} \u0e40\u0e01\u0e34\u0e19\u0e02\u0e35\u0e14\u0e08\u0e33\u0e01\u0e31\u0e14\u0e04\u0e27\u0e32\u0e21\u0e22\u0e32\u0e27\u0e2a\u0e39\u0e07\u0e2a\u0e38\u0e14",//no i18n
"crm.lower.now":"\u0e15\u0e2d\u0e19\u0e19\u0e35\u0e49",//no i18n
"crm.time.min.ago":"{0} \u0e19\u0e32\u0e17\u0e35\u0e17\u0e35\u0e48\u0e41\u0e25\u0e49\u0e27",//no i18n
"crm.time.mins.ago":"{0} \u0e19\u0e32\u0e17\u0e35\u0e17\u0e35\u0e48\u0e41\u0e25\u0e49\u0e27",//no i18n
"crm.time.hr.ago":"{0} \u0e0a\u0e31\u0e48\u0e27\u0e42\u0e21\u0e07\u0e17\u0e35\u0e48\u0e41\u0e25\u0e49\u0e27",//no i18n
"crm.time.hrs.ago":"{0} \u0e0a\u0e31\u0e48\u0e27\u0e42\u0e21\u0e07\u0e17\u0e35\u0e48\u0e41\u0e25\u0e49\u0e27",//no i18n
"AllUsers":"\u0e1c\u0e39\u0e49\u0e43\u0e0a\u0e49\u0e17\u0e31\u0e49\u0e07\u0e2b\u0e21\u0e14",//no i18n
"crm.label.search":"\u0e04\u0e49\u0e19\u0e2b\u0e32",//no i18n
"crm.api.filterby":"\u0e01\u0e23\u0e2d\u0e07\u0e15\u0e32\u0e21",//no i18n
"crm.customview.nofields.found":"--\u0e44\u0e21\u0e48\u0e21\u0e35\u0e40\u0e02\u0e15\u0e02\u0e49\u0e2d\u0e21\u0e39\u0e25\u0e17\u0e35\u0e48\u0e15\u0e23\u0e07\u0e01\u0e31\u0e19--",//no i18n
"crm.setup.system.ziarecommendation":"\u0e01\u0e32\u0e23\u0e41\u0e19\u0e30\u0e19\u0e33",//no i18n
"crm.filter.label.all.products":"{0} \u0e17\u0e31\u0e49\u0e07\u0e2b\u0e21\u0e14",//no i18n
"crm.filter.label.select.products":"{0} \u0e17\u0e35\u0e48\u0e40\u0e25\u0e37\u0e2d\u0e01",//no i18n
"crm.reviewprocess.smart.filter":"\u0e2a\u0e16\u0e32\u0e19\u0e30\u0e23\u0e30\u0e40\u0e1a\u0e35\u0e22\u0e19\u0e43\u0e19\u0e01\u0e23\u0e30\u0e1a\u0e27\u0e19\u0e01\u0e32\u0e23\u0e17\u0e1a\u0e17\u0e27\u0e19",//no i18n
"crm.dashboard.sharing.empty.value.alert":"\u0e42\u0e1b\u0e23\u0e14\u0e40\u0e25\u0e37\u0e2d\u0e01\u0e04\u0e48\u0e32",//no i18n
"crm.segmentation.segment.score":"\u0e04\u0e30\u0e41\u0e19\u0e19\u0e2a\u0e48\u0e27\u0e19",//no i18n
"crm.filter.label.in":"\u0e2d\u0e34\u0e07\u0e15\u0e32\u0e21",//no i18n
"crm.filter.label.and.purchase.in":"\u0e41\u0e25\u0e30\u0e21\u0e35\u0e41\u0e19\u0e27\u0e42\u0e19\u0e49\u0e21\u0e17\u0e35\u0e48\u0e08\u0e30\u0e0b\u0e37\u0e49\u0e2d\u0e43\u0e19",//no i18n
"crm.filter.label.last.purchased":"\u0e41\u0e25\u0e30\u0e40\u0e1e\u0e34\u0e48\u0e07\u0e44\u0e14\u0e49\u0e21\u0e32",//no i18n
"crm.filter.label.a.day":"\u0e2b\u0e19\u0e36\u0e48\u0e07\u0e27\u0e31\u0e19",//no i18n
"crm.filter.label.a.week":"\u0e2b\u0e19\u0e36\u0e48\u0e07\u0e2a\u0e31\u0e1b\u0e14\u0e32\u0e2b\u0e4c",//no i18n
"crm.filter.label.a.month":"\u0e2b\u0e19\u0e36\u0e48\u0e07\u0e40\u0e14\u0e37\u0e2d\u0e19",//no i18n
"crm.cal.custom":"\u0e01\u0e33\u0e2b\u0e19\u0e14\u0e40\u0e2d\u0e07",//no i18n
"crm.mb.field.common.empt":"\u0e04\u0e48\u0e32\u0e44\u0e21\u0e48\u0e2a\u0e32\u0e21\u0e32\u0e23\u0e16\u0e27\u0e48\u0e32\u0e07\u0e40\u0e1b\u0e25\u0e48\u0e32",//no i18n
"crm.chosen.error.loading.text":"\u0e02\u0e2d\u0e2d\u0e20\u0e31\u0e22 \u0e40\u0e23\u0e32\u0e44\u0e21\u0e48\u0e2a\u0e32\u0e21\u0e32\u0e23\u0e16\u0e42\u0e2b\u0e25\u0e14\u0e1c\u0e25\u0e25\u0e31\u0e1e\u0e18\u0e4c\u0e02\u0e2d\u0e07\u0e04\u0e38\u0e13",//no i18n
"crm.filter.label.firstbuy":"\u0e04\u0e23\u0e31\u0e49\u0e07\u0e41\u0e23\u0e01",//no i18n
"crm.filter.label.cwbab":"\u0e04\u0e27\u0e32\u0e21\u0e02\u0e36\u0e49\u0e19\u0e15\u0e48\u0e2d\u0e01\u0e31\u0e19",//no i18n
"crm.filter.label.fbt":"\u0e01\u0e25\u0e38\u0e48\u0e21\u0e07\u0e32\u0e19",//no i18n
"crm.filter.label.rebuy":"\u0e17\u0e33\u0e0b\u0e49\u0e33",//no i18n
"crm.filter.label.nextbuy":"\u0e25\u0e33\u0e14\u0e31\u0e1a",//no i18n
"crm.mxnlookup.select":"\u0e21\u0e2d\u0e1a\u0e2b\u0e21\u0e32\u0e22 {0}",//no i18n
"crm.lookup.chooserecord":"\u0e40\u0e25\u0e37\u0e2d\u0e01 {0}",//no i18n
"crm.record.selected":"{0} \u0e17\u0e35\u0e48\u0e40\u0e25\u0e37\u0e2d\u0e01",//no i18n
"crm.module.empty.message":"\u0e44\u0e21\u0e48\u0e1e\u0e1a {0}",//no i18n
"crm.mxnlookup.selected":"{0} \u0e17\u0e35\u0e48\u0e21\u0e2d\u0e1a\u0e2b\u0e21\u0e32\u0e22",//no i18n
"crm.security.error":"\u0e04\u0e38\u0e13\u0e44\u0e21\u0e48\u0e21\u0e35\u0e2a\u0e34\u0e17\u0e18\u0e34\u0e4c\u0e2d\u0e19\u0e38\u0e0d\u0e32\u0e15\u0e40\u0e1e\u0e35\u0e22\u0e07\u0e1e\u0e2d\u0e43\u0e2b\u0e49\u0e14\u0e33\u0e40\u0e19\u0e34\u0e19\u0e01\u0e32\u0e23\u0e01\u0e23\u0e30\u0e1a\u0e27\u0e19\u0e01\u0e32\u0e23\u0e19\u0e35\u0e49 \u0e42\u0e1b\u0e23\u0e14\u0e15\u0e34\u0e14\u0e15\u0e48\u0e2d\u0e01\u0e31\u0e1a\u0e1c\u0e39\u0e49\u0e14\u0e39\u0e41\u0e25\u0e23\u0e30\u0e1a\u0e1a\u0e02\u0e2d\u0e07\u0e04\u0e38\u0e13",//no i18n
"crm.label.creator.noPermission":"\u0e2a\u0e34\u0e17\u0e18\u0e34\u0e4c\u0e16\u0e39\u0e01\u0e1b\u0e0f\u0e34\u0e40\u0e2a\u0e18",//no i18n
"crm.segmentation.recency":"\u0e04\u0e27\u0e32\u0e21\u0e43\u0e2b\u0e21\u0e48",//no i18n
"crm.segmentation.frequency":"\u0e04\u0e27\u0e32\u0e21\u0e16\u0e35\u0e48",//no i18n
"crm.segmentation.monetary":"\u0e01\u0e32\u0e23\u0e40\u0e07\u0e34\u0e19",//no i18n
"crm.smartfilter.related.module.msg":"\u0e04\u0e38\u0e13\u0e44\u0e21\u0e48\u0e2a\u0e32\u0e21\u0e32\u0e23\u0e16\u0e40\u0e25\u0e37\u0e2d\u0e01\u0e21\u0e2d\u0e14\u0e39\u0e25\u0e17\u0e35\u0e48\u0e40\u0e01\u0e35\u0e48\u0e22\u0e27\u0e02\u0e49\u0e2d\u0e07\u0e44\u0e14\u0e49\u0e21\u0e32\u0e01\u0e01\u0e27\u0e48\u0e32 3 \u0e23\u0e32\u0e22\u0e01\u0e32\u0e23",//no i18n
"crm.smartfilter.related.module.msg1":"(\u0e15.\u0e22.: \u0e2d\u0e35\u0e40\u0e21\u0e25 \u0e01\u0e34\u0e08\u0e01\u0e23\u0e23\u0e21 \u0e1a\u0e31\u0e19\u0e17\u0e36\u0e01\u0e22\u0e48\u0e2d)",//no i18n
"crm.smartfilter.related.module.msg2":"\u0e0a\u0e48\u0e27\u0e07\u0e40\u0e27\u0e25\u0e32\u0e44\u0e21\u0e48\u0e2a\u0e32\u0e21\u0e32\u0e23\u0e16\u0e27\u0e48\u0e32\u0e07\u0e40\u0e1b\u0e25\u0e48\u0e32", //no i18n
"crm.label.timeZone": "\u0e40\u0e02\u0e15\u0e40\u0e27\u0e25\u0e32", //NO I18n
"crm.label.insufficient.privileges": "\u0e2a\u0e34\u0e17\u0e18\u0e34\u0e4c\u0e44\u0e21\u0e48\u0e40\u0e1e\u0e35\u0e22\u0e07\u0e1e\u0e2d\u0e43\u0e19\u0e01\u0e32\u0e23\u0e17\u0e33\u0e01\u0e32\u0e23\u0e14\u0e33\u0e40\u0e19\u0e34\u0e19\u0e01\u0e32\u0e23\u0e19\u0e35\u0e49 \u0e15\u0e34\u0e14\u0e15\u0e48\u0e2d\u0e1c\u0e39\u0e49\u0e14\u0e39\u0e41\u0e25\u0e23\u0e30\u0e1a\u0e1a\u0e02\u0e2d\u0e07\u0e04\u0e38\u0e13", //NO I18n
"crm.filter.header.secton.system": "\u0e15\u0e31\u0e27\u0e01\u0e23\u0e2d\u0e07\u0e17\u0e35\u0e48\u0e01\u0e33\u0e2b\u0e19\u0e14\u0e42\u0e14\u0e22\u0e23\u0e30\u0e1a\u0e1a", //NO I18N
"crm.filter.header.secton.fields": "\u0e01\u0e23\u0e2d\u0e07\u0e15\u0e32\u0e21\u0e40\u0e02\u0e15\u0e02\u0e49\u0e2d\u0e21\u0e39\u0e25", //NO I18N
"crm.createfield.calcinfo.new" : "\u0e40\u0e02\u0e15\u0e02\u0e49\u0e2d\u0e21\u0e39\u0e25\u0e19\u0e35\u0e49\u0e17\u0e33\u0e2b\u0e19\u0e49\u0e32\u0e17\u0e35\u0e48\u0e40\u0e1b\u0e47\u0e19\u0e40\u0e04\u0e23\u0e37\u0e48\u0e2d\u0e07\u0e04\u0e33\u0e19\u0e27\u0e13\u0e2a\u0e33\u0e2b\u0e23\u0e31\u0e1a\u0e19\u0e34\u0e1e\u0e08\u0e19\u0e4c\u0e17\u0e35\u0e48\u0e04\u0e38\u0e13\u0e1b\u0e49\u0e2d\u0e19</br> <b>\u0e40\u0e0a\u0e48\u0e19 20+20</b> \u0e08\u0e30\u0e43\u0e2b\u0e49\u0e1c\u0e25 <b>40</b> \u0e42\u0e14\u0e22\u0e2d\u0e31\u0e15\u0e42\u0e19\u0e21\u0e31\u0e15\u0e34",//No i18n
"crm.lable.read.only" : "\u0e40\u0e02\u0e15\u0e02\u0e49\u0e2d\u0e21\u0e39\u0e25\u0e2d\u0e48\u0e32\u0e19\u0e2d\u0e22\u0e32\u0e07\u0e40\u0e14\u0e35\u0e22\u0e27",//No i18n
"crm.column.sort.asc" : "\u0e19\u0e49\u0e2d\u0e22\u0e44\u0e1b\u0e2b\u0e32\u0e21\u0e32\u0e01",//No I18n
"crm.column.sort.desc" : "\u0e21\u0e32\u0e01\u0e44\u0e1b\u0e2b\u0e32\u0e19\u0e49\u0e2d\u0e22",//No i18n
	"crm.column.unsort" : "\u0e22\u0e01\u0e40\u0e25\u0e34\u0e01\u0e01\u0e32\u0e23\u0e40\u0e23\u0e35\u0e22\u0e07\u0e25\u0e33\u0e14\u0e31\u0e1a",//No I18n
	"custmr.prtl.notes.shr.to.custmr": "\u0e41\u0e0a\u0e23\u0e4c\u0e44\u0e1b\u0e22\u0e31\u0e07\u0e25\u0e39\u0e01\u0e04\u0e49\u0e32", //NO I18N
"crm.label.edited": "Edited", //NO I18N
"crm.label.edited.on": "Edited on", //NO I18N
"crm.message.limit.exceed": "\u0e2d\u0e19\u0e38\u0e0d\u0e32\u0e15\u0e44\u0e21\u0e48\u0e40\u0e01\u0e34\u0e19 {0} \u0e15\u0e31\u0e27\u0e2d\u0e31\u0e01\u0e02\u0e23\u0e30\u0e2a\u0e33\u0e2b\u0e23\u0e31\u0e1a {1}", //NO I18N
"custmr.prtl.notes.shrd.with.custmr": "\u0e17\u0e35\u0e48\u0e41\u0e0a\u0e23\u0e4c\u0e01\u0e31\u0e1a\u0e25\u0e39\u0e01\u0e04\u0e49\u0e32", //NO I18N
"crm.button.ok" : "\u0e15\u0e01\u0e25\u0e07", //NO I18N
"crm.role.already.selected" : "\u0e21\u0e35\u0e01\u0e32\u0e23\u0e40\u0e25\u0e37\u0e2d\u0e01\u0e1a\u0e17\u0e1a\u0e32\u0e17\u0e19\u0e35\u0e49\u0e44\u0e27\u0e49\u0e41\u0e25\u0e49\u0e27", //no i18n
"crm.user.deleted": "\u0e1c\u0e39\u0e49\u0e43\u0e0a\u0e49\u0e16\u0e39\u0e01\u0e25\u0e1a\u0e41\u0e25\u0e49\u0e27",  //NO I18N
"crm.account.closed": "\u0e1a\u0e31\u0e0d\u0e0a\u0e35\u0e19\u0e35\u0e49\u0e16\u0e39\u0e01\u0e1b\u0e34\u0e14\u0e41\u0e25\u0e49\u0e27",  //NO I18N
"crm.start.chat": "\u0e40\u0e23\u0e34\u0e48\u0e21\u0e01\u0e32\u0e23\u0e2a\u0e19\u0e17\u0e19\u0e32",  //NO I18N
"crm.start.call": "\u0e40\u0e23\u0e34\u0e48\u0e21\u0e01\u0e32\u0e23\u0e42\u0e17\u0e23",  //NO I18N
"crm.start.video.call": "\u0e40\u0e23\u0e34\u0e48\u0e21\u0e01\u0e32\u0e23\u0e2a\u0e19\u0e17\u0e19\u0e32\u0e17\u0e32\u0e07\u0e27\u0e34\u0e14\u0e35\u0e42\u0e2d",  //NO I18N
"crm.recipient.invalid.email" : "\u0e1e\u0e1a\u0e2d\u0e35\u0e40\u0e21\u0e25\u0e17\u0e35\u0e48\u0e44\u0e21\u0e48\u0e16\u0e39\u0e01\u0e15\u0e49\u0e2d\u0e07", //NO I18N
"crm.recipient.add.recipient" : "\u0e40\u0e1e\u0e34\u0e48\u0e21\u0e1c\u0e39\u0e49\u0e23\u0e31\u0e1a\u0e40\u0e1e\u0e34\u0e48\u0e21\u0e40\u0e15\u0e34\u0e21", //NO I18N

"crm.label.scoring.rules":"\u0e01\u0e0e\u0e01\u0e32\u0e23\u0e43\u0e2b\u0e49\u0e04\u0e30\u0e41\u0e19\u0e19",//NO I18N
"Score":"\u0e04\u0e30\u0e41\u0e19\u0e19",//NO I18N
"Positive Score":"\u0e04\u0e30\u0e41\u0e19\u0e19\u0e14\u0e49\u0e32\u0e19\u0e1a\u0e27\u0e01",//NO I18N
"Negative Score":"\u0e04\u0e30\u0e41\u0e19\u0e19\u0e14\u0e49\u0e32\u0e19\u0e25\u0e1a",//NO I18N
"Touch Point Score":"\u0e04\u0e30\u0e41\u0e19\u0e19\u0e08\u0e38\u0e14\u0e15\u0e34\u0e14\u0e15\u0e48\u0e2d",//NO I18N
"Positive Touch Point Score":"\u0e04\u0e30\u0e41\u0e19\u0e19\u0e08\u0e38\u0e14\u0e15\u0e34\u0e14\u0e15\u0e48\u0e2d\u0e14\u0e49\u0e32\u0e19\u0e1a\u0e27\u0e01",//NO I18N
"Negative Touch Point Score":"\u0e04\u0e30\u0e41\u0e19\u0e19\u0e08\u0e38\u0e14\u0e15\u0e34\u0e14\u0e15\u0e48\u0e2d\u0e14\u0e49\u0e32\u0e19\u0e25\u0e1a",//NO I18N
"crm.label.type.minutes": "\u0e1e\u0e34\u0e21\u0e1e\u0e4c\u0e43\u0e19\u0e2b\u0e19\u0e48\u0e27\u0e22\u0e19\u0e32\u0e17\u0e35", //NO I18N

"is\ OPEN":"\u0e21\u0e35\u0e2a\u0e16\u0e32\u0e19\u0e30\u0e40\u0e1b\u0e34\u0e14\u0e2d\u0e22\u0e39\u0e48",//no i18n
"is\ WON":"\u0e21\u0e35\u0e2a\u0e16\u0e32\u0e19\u0e30\u0e44\u0e14\u0e49\u0e40\u0e1b\u0e47\u0e19\u0e25\u0e39\u0e01\u0e04\u0e49\u0e32",//no i18n
"is\ LOST":"\u0e21\u0e35\u0e2a\u0e16\u0e32\u0e19\u0e30\u0e2a\u0e39\u0e0d\u0e40\u0e2a\u0e35\u0e22\u0e41\u0e25\u0e49\u0e27",//no i18n
"crm.potential.all.open":"\u0e02\u0e31\u0e49\u0e19\u0e15\u0e2d\u0e19\u0e17\u0e35\u0e48\u0e40\u0e1b\u0e34\u0e14\u0e2d\u0e22\u0e39\u0e48\u0e17\u0e31\u0e49\u0e07\u0e2b\u0e21\u0e14",//no i18n
"crm.potential.all.won":"\u0e02\u0e31\u0e49\u0e19\u0e15\u0e2d\u0e19\u0e44\u0e14\u0e49\u0e23\u0e31\u0e1a\u0e40\u0e1b\u0e47\u0e19\u0e25\u0e39\u0e01\u0e04\u0e49\u0e32\u0e17\u0e35\u0e48\u0e1b\u0e34\u0e14\u0e41\u0e25\u0e49\u0e27\u0e17\u0e31\u0e49\u0e07\u0e2b\u0e21\u0e14",//no i18n

"crm.potential.all.lost":"\u0e02\u0e31\u0e49\u0e19\u0e15\u0e2d\u0e19\u0e01\u0e32\u0e23\u0e2a\u0e39\u0e0d\u0e40\u0e2a\u0e35\u0e22\u0e44\u0e1b\u0e17\u0e35\u0e48\u0e1b\u0e34\u0e14\u0e41\u0e25\u0e49\u0e27\u0e17\u0e31\u0e49\u0e07\u0e2b\u0e21\u0e14",//no i18n
"crm.campaign.member.status" : "\u0e2a\u0e16\u0e32\u0e19\u0e30\u0e2a\u0e21\u0e32\u0e0a\u0e34\u0e01",//no i18n
"crm.dashboard.select.type" : "\u0e40\u0e25\u0e37\u0e2d\u0e01 {0}",//no i18n
"crm.campaign.service.status":"\u0e2a\u0e16\u0e32\u0e19\u0e30\u0e1a\u0e23\u0e34\u0e01\u0e32\u0e23",//no i18n

"crm.label.addColumn":"\u0e40\u0e1e\u0e34\u0e48\u0e21\u0e04\u0e2d\u0e25\u0e31\u0e21\u0e19\u0e4c",//no i18n
"crm.button.clear.filter":"\u0e1b\u0e34\u0e14\u0e15\u0e31\u0e27\u0e01\u0e23\u0e2d\u0e07",//no i18n
"crm.button.show.filter":"\u0e41\u0e2a\u0e14\u0e07\u0e15\u0e31\u0e27\u0e01\u0e23\u0e2d\u0e07",//no i18n
"crm.las.error.user.maxlimit":"\u0e04\u0e38\u0e13\u0e2a\u0e32\u0e21\u0e32\u0e23\u0e16\u0e40\u0e25\u0e37\u0e2d\u0e01\u0e1c\u0e39\u0e49\u0e43\u0e0a\u0e49\u0e44\u0e14\u0e49\u0e2a\u0e39\u0e07\u0e2a\u0e38\u0e14 20 \u0e04\u0e19\u0e40\u0e17\u0e48\u0e32\u0e19\u0e31\u0e49\u0e19",//no i18n
"crm.las.error.picklist.maxlimit":"\u0e04\u0e38\u0e13\u0e2a\u0e32\u0e21\u0e32\u0e23\u0e16\u0e40\u0e25\u0e37\u0e2d\u0e01\u0e15\u0e31\u0e27\u0e40\u0e25\u0e37\u0e2d\u0e01\u0e44\u0e14\u0e49\u0e2a\u0e39\u0e07\u0e2a\u0e38\u0e14 20 \u0e23\u0e32\u0e22\u0e01\u0e32\u0e23\u0e40\u0e17\u0e48\u0e32\u0e19\u0e31\u0e49\u0e19",//no i18n

"crm.fileuploader.message.responseerror": "\u0e2d\u0e31\u0e1b\u0e42\u0e2b\u0e25\u0e14\u0e25\u0e49\u0e21\u0e40\u0e2b\u0e25\u0e27", //NO I18N
"crm.storage.create.error": "\u0e44\u0e21\u0e48\u0e2a\u0e32\u0e21\u0e32\u0e23\u0e16\u0e2a\u0e23\u0e49\u0e32\u0e07\u0e23\u0e30\u0e40\u0e1a\u0e35\u0e22\u0e19\u0e43\u0e2b\u0e21\u0e48 \u0e40\u0e19\u0e37\u0e48\u0e2d\u0e07\u0e08\u0e32\u0e01\u0e04\u0e38\u0e13\u0e43\u0e0a\u0e49\u0e16\u0e36\u0e07\u0e02\u0e35\u0e14\u0e08\u0e33\u0e01\u0e31\u0e14\u0e1e\u0e37\u0e49\u0e19\u0e17\u0e35\u0e48\u0e40\u0e01\u0e47\u0e1a\u0e02\u0e49\u0e2d\u0e21\u0e39\u0e25\u0e2a\u0e39\u0e07\u0e2a\u0e38\u0e14\u0e02\u0e2d\u0e07\u0e04\u0e38\u0e13\u0e41\u0e25\u0e49\u0e27", //NO I18N
"crm.storage.create.error.client": "\u0e44\u0e21\u0e48\u0e2a\u0e32\u0e21\u0e32\u0e23\u0e16\u0e2a\u0e23\u0e49\u0e32\u0e07\u0e23\u0e30\u0e40\u0e1a\u0e35\u0e22\u0e19\u0e43\u0e2b\u0e21\u0e48 \u0e40\u0e19\u0e37\u0e48\u0e2d\u0e07\u0e08\u0e32\u0e01\u0e1c\u0e39\u0e49\u0e14\u0e39\u0e41\u0e25\u0e23\u0e30\u0e1a\u0e1a\u0e02\u0e2d\u0e07\u0e04\u0e38\u0e13\u0e43\u0e0a\u0e49\u0e16\u0e36\u0e07\u0e02\u0e35\u0e14\u0e08\u0e33\u0e01\u0e31\u0e14\u0e1e\u0e37\u0e49\u0e19\u0e17\u0e35\u0e48\u0e40\u0e01\u0e47\u0e1a\u0e02\u0e49\u0e2d\u0e21\u0e39\u0e25\u0e2a\u0e39\u0e07\u0e2a\u0e38\u0e14\u0e41\u0e25\u0e49\u0e27 \u0e15\u0e34\u0e14\u0e15\u0e48\u0e2d {0} \u0e40\u0e1e\u0e37\u0e48\u0e2d\u0e41\u0e01\u0e49\u0e44\u0e02\u0e1b\u0e31\u0e0d\u0e2b\u0e32\u0e19\u0e35\u0e49", //NO I18N
"crm.storage.avail.info": "({0} \u0e04\u0e07\u0e40\u0e2b\u0e25\u0e37\u0e2d\u0e08\u0e32\u0e01 {1})", //NO I18N
"crm.storage.error.key.manage": "\u0e08\u0e31\u0e14\u0e01\u0e32\u0e23\u0e1e\u0e37\u0e49\u0e19\u0e17\u0e35\u0e48\u0e40\u0e01\u0e47\u0e1a\u0e02\u0e49\u0e2d\u0e21\u0e39\u0e25\u0e02\u0e2d\u0e07\u0e04\u0e38\u0e13", //NO I18N
"Records": "\u0e23\u0e30\u0e40\u0e1a\u0e35\u0e22\u0e19", //NO I18N
"crm.zcampaign.test.email.input.placeholder" : "\u0e1b\u0e49\u0e2d\u0e19\u0e17\u0e35\u0e48\u0e2d\u0e22\u0e39\u0e48\u0e2d\u0e35\u0e40\u0e21\u0e25", //NO I18N
"crm.workflow.alert.additional.recipients" : "\u0e1c\u0e39\u0e49\u0e23\u0e31\u0e1a\u0e40\u0e1e\u0e34\u0e48\u0e21\u0e40\u0e15\u0e34\u0e21", //NO I18N
"crm.workflow.alert.type.otherEmails" : "\u0e43\u0e0a\u0e49\u0e40\u0e04\u0e23\u0e37\u0e48\u0e2d\u0e07\u0e2b\u0e21\u0e32\u0e22\u0e08\u0e38\u0e25\u0e20\u0e32\u0e04\u0e40\u0e1e\u0e37\u0e48\u0e2d\u0e04\u0e31\u0e48\u0e19\u0e17\u0e35\u0e48\u0e2d\u0e22\u0e39\u0e48\u0e2d\u0e35\u0e40\u0e21\u0e25\u0e40\u0e1e\u0e34\u0e48\u0e21\u0e40\u0e15\u0e34\u0e21", //NO I18N
"crm.related.contact.account" : "{0} \u0e17\u0e35\u0e48\u0e40\u0e01\u0e35\u0e48\u0e22\u0e27\u0e02\u0e49\u0e2d\u0e07\u0e01\u0e31\u0e1a {1}",//No I18n
	"crm.allcontact.show" : "{0} \u0e17\u0e31\u0e49\u0e07\u0e2b\u0e21\u0e14",//No I18n
	"crm.button.mass.show" : "\u0e41\u0e2a\u0e14\u0e07",//No I18n
"crm.msg.custom.view.not.replied" : "\u0e02\u0e49\u0e2d\u0e04\u0e27\u0e32\u0e21\u0e17\u0e35\u0e48\u0e22\u0e31\u0e07\u0e44\u0e21\u0e48\u0e44\u0e14\u0e49\u0e15\u0e2d\u0e1a\u0e01\u0e25\u0e31\u0e1a", //NO I18N
	"crm.msg.custom.view.replied" : "\u0e02\u0e49\u0e2d\u0e04\u0e27\u0e32\u0e21\u0e17\u0e35\u0e48\u0e15\u0e2d\u0e1a\u0e01\u0e25\u0e31\u0e1a\u0e41\u0e25\u0e49\u0e27",//NO I18N
	"crm.workflow.select.recipients" : "\u0e1c\u0e39\u0e49\u0e23\u0e31\u0e1a" ,//NO I18N
	"crm.custom.module.no.profile.selected.alert1":"\u0e42\u0e1b\u0e23\u0e14\u0e40\u0e25\u0e37\u0e2d\u0e01\u0e2d\u0e22\u0e48\u0e32\u0e07\u0e19\u0e49\u0e2d\u0e22\u0e2b\u0e19\u0e36\u0e48\u0e07\u0e42\u0e1b\u0e23\u0e44\u0e1f\u0e25\u0e4c",//NO I18N
	"crm.auto.enrich.remove.default.profile" : "\u0e02\u0e2d\u0e2d\u0e20\u0e31\u0e22 \u0e04\u0e38\u0e13\u0e44\u0e21\u0e48\u0e2a\u0e32\u0e21\u0e32\u0e23\u0e16\u0e19\u0e33\u0e42\u0e1b\u0e23\u0e44\u0e1f\u0e25\u0e4c\u0e40\u0e23\u0e34\u0e48\u0e21\u0e15\u0e49\u0e19\u0e2d\u0e2d\u0e01",//NO I18N
	"crm.inv.label.add.emails" : "\u0e40\u0e1e\u0e34\u0e48\u0e21\u0e2d\u0e35\u0e40\u0e21\u0e25", //NO I18N
	"crm.prediction.analytics.filter.year":"\u0e1b\u0e35\u0e17\u0e35\u0e48\u0e41\u0e25\u0e49\u0e27",//no i18n
	"Previous\ FY":"FY \u0e01\u0e48\u0e2d\u0e19\u0e2b\u0e19\u0e49\u0e32",//no i18n
	"Current\ FY":"FY \u0e1b\u0e31\u0e08\u0e08\u0e38\u0e1a\u0e31\u0e19",//no i18n
	"Next\ FY":"FY \u0e16\u0e31\u0e14\u0e44\u0e1b",//no i18n
	"Current\ FQ":"FQ \u0e1b\u0e31\u0e08\u0e08\u0e38\u0e1a\u0e31\u0e19",//no i18n
	"Next\ FQ":"FQ \u0e16\u0e31\u0e14\u0e44\u0e1b",//no i18n
	"Previous\ FQ":"FQ \u0e01\u0e48\u0e2d\u0e19\u0e2b\u0e19\u0e49\u0e32",//no i18n
	"crm.picklist.sample.text":"\u0e02\u0e49\u0e2d\u0e04\u0e27\u0e32\u0e21\u0e15\u0e31\u0e27\u0e2d\u0e22\u0e48\u0e32\u0e07",//no i18n
	"crm.more.colors":"\u0e2a\u0e35\u0e40\u0e1e\u0e34\u0e48\u0e21\u0e40\u0e15\u0e34\u0e21",//no i18n
	"crm.button.back.alone":"\u0e22\u0e49\u0e2d\u0e19\u0e01\u0e25\u0e31\u0e1a",//no i18n
	"crm.field.label.email":"\u0e2d\u0e35\u0e40\u0e21\u0e25",//no i18n
	"crm.zia.nba.feature.label":"\u0e01\u0e32\u0e23\u0e14\u0e33\u0e40\u0e19\u0e34\u0e19\u0e01\u0e32\u0e23\u0e17\u0e35\u0e48\u0e14\u0e35\u0e17\u0e35\u0e48\u0e2a\u0e38\u0e14\u0e04\u0e23\u0e31\u0e49\u0e07\u0e16\u0e31\u0e14\u0e44\u0e1b",//no i18n
	"Meeting":"\u0e01\u0e32\u0e23\u0e1b\u0e23\u0e30\u0e0a\u0e38\u0e21",//no i18n
	"crm.gdpr.notavailable.field":"\u0e44\u0e21\u0e48\u0e1e\u0e23\u0e49\u0e2d\u0e21\u0e43\u0e0a\u0e49\u0e07\u0e32\u0e19",//no i18n
	"crm.setup.system.ziasimilarity":"\u0e01\u0e32\u0e23\u0e41\u0e19\u0e30\u0e19\u0e33\u0e04\u0e27\u0e32\u0e21\u0e04\u0e25\u0e49\u0e32\u0e22\u0e04\u0e25\u0e36\u0e07",//no i18n
	"crm.gdpr.notavailable.field":"\u0e44\u0e21\u0e48\u0e1e\u0e23\u0e49\u0e2d\u0e21\u0e43\u0e0a\u0e49\u0e07\u0e32\u0e19",//no i18n
	"crm.filter.label.all.products":"{0} \u0e17\u0e31\u0e49\u0e07\u0e2b\u0e21\u0e14",//NO I18N
	'crm.zia.nbx.filter.due' : "Due", //NO I18N
	"abm.segment": "Segment", // NO I18N
	"abm.segment.names": "Segment Names", // NO I18N
	"abm.abm.segment.name": "ABM Segment Name", // NO I18N
	"abm.segmentation.techniques": "ABM Segmentation Techniques", // NO I18N
	"abm.filter.by.abm.fields": "Filter By ABM Fields", // NO I18N
	"abm.rfm": "RFM", // NO I18N
	"abm.firmographics": "Firmographics", // NO I18N
	"crux.custom.field.greater.than.equalto":"{0} จะต้องมากกว่าหรือเท่ากับ {1}",
	"crux.users.selected.plural" : "{0} ผู้ใช้ที่เลือก",
	"crux.user.selected.singular" :"{0} ผู้ใช้ที่เลือก",
	"crux.criteria.empty.secondaryfield.module" : "ไม่พบเขตข้อมูล {0} ที่ตรงกันในมอดูล {1}",
	"crux.criteria.empty.secondaryfield" : "ไม่มีเขตข้อมูล {0} อื่นๆ ที่ใช้ได้สำหรับการเปรียบเทียบ โปรดป้อนค่าเพื่อเปรียบเทียบ",
	"crux.logged.in.role.definition" : "บทบาทผู้ใช้ที่เริ่มการดำเนินการของระเบียน",
	"zia.similarity.smartfilter.score":"\u0e04\u0e30\u0e41\u0e19\u0e19\u0e04\u0e27\u0e32\u0e21\u0e04\u0e25\u0e49\u0e32\u0e22\u0e04\u0e25\u0e36\u0e07",//no i18n
	"zia.similarity.smartfilter.records":"\u0e04\u0e25\u0e49\u0e32\u0e22\u0e04\u0e25\u0e36\u0e07\u0e01\u0e31\u0e1a {0} \u0e02\u0e2d\u0e07",//no i18n
	"zia.similarity.smartfilter.records.search":"\u0e41\u0e2a\u0e14\u0e07\u0e04\u0e27\u0e32\u0e21\u0e04\u0e25\u0e49\u0e32\u0e22\u0e04\u0e25\u0e36\u0e07\u0e01\u0e31\u0e1a {0} \u0e02\u0e2d\u0e07",//no i18n
	"crux.smartfilter.multiselect.maximum.selected":"\u0e04\u0e38\u0e13\u0e44\u0e21\u0e48\u0e2a\u0e32\u0e21\u0e32\u0e23\u0e16\u0e40\u0e25\u0e37\u0e2d\u0e01 {1}\u0e44\u0e14\u0e49\u0e21\u0e32\u0e01\u0e01\u0e27\u0e48\u0e32 {0} \u0e23\u0e32\u0e22\u0e01\u0e32\u0e23",//no i18n
	"crux.custom.field.greater.than.equalto":"{0} จะต้องมากกว่าหรือเท่ากับ {1}",
	"crux.users.selected.plural" : "{0} ผู้ใช้ที่เลือก",
	"crux.user.selected.singular" :"{0} ผู้ใช้ที่เลือก",
	"crux.criteria.empty.secondaryfield.module" : "ไม่พบเขตข้อมูล {0} ที่ตรงกันในมอดูล {1}",
	"crux.criteria.empty.secondaryfield" : "ไม่มีเขตข้อมูล {0} อื่นๆ ที่ใช้ได้สำหรับการเปรียบเทียบ โปรดป้อนค่าเพื่อเปรียบเทียบ",
	"crux.logged.in.role.definition" : "บทบาทผู้ใช้ที่เริ่มการดำเนินการของระเบียน",
	"crux.max.limit.unselect" : "คุณไม่สามารถยกเลิกเลือกมากกว่า {0} {1}", //No I18N
	"crux.existing.tag" : "\u201d{0}\u201c ถูกเลือกอยู่แล้ว" //NO I18N
}
